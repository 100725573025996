import React from 'react';
import { Dialog, DialogContent, DialogActions, TextField, Stack, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './ReportsOrdersDialog.scss';

export default function ReportsDetailsDialog({ open, onClose, log }) {
    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="order-details-dialog" sx={{ '& .MuiDialog-paper': { maxWidth: '800px', width: '100%' } }}>
            <DialogActions>
                <IconButton onClick={onClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
            </DialogActions>
            <DialogContent className="popup-form__wrapper1">
                {log && (
                    <Stack spacing={2} className="popup-form__wrapper">
                        <div className='inputs__wrapper'>
                            <TextField id="id" label="ID" className="popup-form__input" variant="outlined" value={log.id || ' '} disabled />
                        </div>
                        <div className='inputs__wrapper'>
                            <TextField id="user_id" label="User ID" className="popup-form__input" variant="outlined" value={log.user_id || ' '} disabled />
                        </div>
                        <div className='inputs__wrapper'>
                            <TextField id="OrdineID" label="Order ID" className="popup-form__input" variant="outlined" value={log.OrdineID || ' '} disabled />
                        </div>
                        <div className='inputs__wrapper'>
                            <TextField id="StripePaymentID" label="Stripe Payment ID" className="popup-form__input" variant="outlined" value={log.StripePaymentID || ' '} disabled />
                        </div>
                        <div className='inputs__wrapper'>
                            <TextField id="StripeSessionID" label="Stripe Session ID" className="popup-form__input" variant="outlined" value={log.StripeSessionID || ' '} disabled />
                        </div>
                        <div className='inputs__wrapper'>
                            <TextField id="StripeCustomerID" label="Stripe Customer ID" className="popup-form__input" variant="outlined" value={log.StripeCustomerID || ' '} disabled />
                        </div>
                        <div className='inputs__wrapper'>
                            <TextField id="EmailCliente" label="Email Cliente" className="popup-form__input" variant="outlined" value={log.EmailCliente || ' '} disabled />
                        </div>
                        <div className='inputs__wrapper'>
                            <TextField id="OggettiComprati" label="Items Purchased" className="popup-form__input" variant="outlined" value={log.OggettiComprati || ' '} disabled />
                        </div>
                        <div className='inputs__wrapper'>
                            <TextField id="Totale" label="Total" className="popup-form__input" variant="outlined" value={log.Totale || ' '} disabled />
                        </div>
                        <div className='inputs__wrapper'>
                            <TextField id="DataOrdineInizio" label="Order Start Date" className="popup-form__input" variant="outlined" value={log.DataOrdineInizio || ' '} disabled />
                        </div>
                        <div className='inputs__wrapper'>
                            <TextField id="DataOrdineFine" label="Order End Date" className="popup-form__input" variant="outlined" value={log.DataOrdineFine || ' '} disabled />
                        </div>
                    </Stack>
                )}
            </DialogContent>
        </Dialog>
    ); 
}
