import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { FormControl, InputLabel, Select, MenuItem, Stack, TextField } from '@mui/material';
import Cookies from 'js-cookie';
import './CouponView.scss';

export default function CouponView() {
    const { id } = useParams();
    const location = useLocation();
    const [coupons, setCoupons] = useState([]);
    const [couponData, setCouponData] = useState({
        id: "",
        couponId: "",
        startDate: "",
        endDate: "",
        idPay: "",
        active: "",
        stato: "",
    });
    const apiKey = Cookies.get('key');


    const fetchCouponData = async (couponId) => {
        try {
            const response = await fetch(`https://admin.omnivisix.io/api/getCoupon.php?coupon_id=${couponId}`, {
                headers: {
                    'api_key': apiKey
                }
            });
            const data = await response.json();
            if (data && data.data) {
                const selectedCoupon = data.data;
                setCouponData({
                    id: selectedCoupon.coupon_id || "",
                    couponId: selectedCoupon.code || "",
                    startDate: selectedCoupon.start_date || "",
                    endDate: selectedCoupon.end_date || "",
                    idPay: selectedCoupon.idpay || "",
                    active: selectedCoupon.attivato || "",
                    stato: selectedCoupon.stato || "",
                });
            }
        } catch (error) {
            console.error('Error fetching coupon data:', error);
        }
    };

    useEffect(() => {
        if (id) {
            fetchCouponData(id);
        }
    }, [id, apiKey]);

    const handleChange = async (event) => {
        const selectedCouponId = event.target.value;
        fetchCouponData(selectedCouponId);
    };

    return (
        <div>
            {!id && (
                <FormControl sx={{ width: 234, marginRight: 1 }}>
                    <InputLabel id="coupon-select-label">Select Coupon</InputLabel>
                    <Select
                        labelId="coupon-select-label"
                        id="coupon-select"
                        label="Select Coupon"
                        onChange={handleChange}
                    >
                        {coupons.map((coupon) => (
                            <MenuItem key={coupon.coupon_id} value={coupon.coupon_id}>
                                {coupon.code}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}
            <div className="coupon__info-wrapper">
                <Stack spacing={3} sx={{ width: 800 }}>
                    <h2 className='form__title'>Coupon INFO</h2>
                    <div className='coupon__inputs-wrapper'>
                        <TextField 
                            sx={{ width: 120 }}
                            className="form__input" 
                            id="id" 
                            label="ID" 
                            variant="outlined" 
                            disabled 
                            value={couponData.id} 
                        />
                    </div>
                    <div className='coupon__inputs-wrapper'>
                        <TextField 
                            sx={{ width: 450 }}
                            className="form__input" 
                            id="coupon_id" 
                            label="Coupon ID" 
                            variant="outlined" 
                            disabled
                            value={couponData.couponId} 
                        />
                    </div>
                    <div className='coupon__inputs-wrapper'>
                        <TextField 
                            sx={{ width: 200 }}
                            className="form__input form__input--small" 
                            id="start_date" 
                            label="Valid from" 
                            type="date"
                            variant="outlined" 
                            value={couponData.startDate}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled
                        />

                        <TextField 
                            sx={{ width: 200 }}
                            className="form__input form__input--small" 
                            id="end_date" 
                            label="to" 
                            type="date"
                            variant="outlined" 
                            value={couponData.endDate}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled
                        />
                    </div>
                    <div className='coupon__inputs-wrapper'>
                        <TextField 
                            sx={{ width: 450 }}
                            className="form__input" 
                            id="id_pay" 
                            label="ID Pay" 
                            variant="outlined" 
                            disabled
                            value={couponData.idPay} 
                        />
                    </div>
                    <div className='coupon__inputs-wrapper'>
                        <FormControl sx={{ width: 180 }}>
                            <InputLabel id="active-label">Active</InputLabel>
                            <Select
                                labelId="active-label"
                                id="active"
                                value={couponData.active === "1" ? "Active" : "Inactive"}
                                label="Active"
                                disabled
                            >
                                <MenuItem value="Active">Active</MenuItem>
                                <MenuItem value="Inactive">Inactive</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl sx={{ width: 180 }}>
                            <InputLabel id="stato-label">Stato</InputLabel>
                            <Select
                                labelId="stato-label"
                                id="stato"
                                value={couponData.stato === "1" ? "Used" : "Not Used"}
                                label="Stato"
                                disabled
                            >
                                <MenuItem value="Used">Used</MenuItem>
                                <MenuItem value="Not Used">Not Used</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </Stack>
            </div>
        </div>
    );
}
