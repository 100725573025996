import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import UserDetailsDialog from "../UserDetailsDialog/UserDetailsDialog";
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import Tooltip from '@mui/material/Tooltip';
import Cookies from 'js-cookie';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';

const StyledGridOverlay = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
});

const CustomLoadingOverlay = () => {
    return (
        <StyledGridOverlay>
            <CircularProgress />
        </StyledGridOverlay>
    );
};

export default function CouponList() {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const apiKey = Cookies.get('key');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await fetch('https://admin.omnivisix.io/api/listCoupons.php', {
                    headers: {
                        'api_key': apiKey
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    const updatedData = data.data.map((item) => ({
                        ...item,
                        user: `${item.given_name ?? ''} ${item.family_name ?? ''}`.trim()
                    }));
                    setRows(updatedData);
                } else {
                    console.error('Failed to fetch data:', response.statusText);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [apiKey]);

    const handleView = (couponId) => {
        navigate(`/coupon/view/${couponId}`);
    };
    
    const handleEdit = (couponId) => {
        navigate(`/coupon/edit/${couponId}`);
    };

    const navigateToCouponActivate = (couponId) => {
        navigate(`/coupon-activate/${couponId}`);
    }

    const columns = [
        { field: 'coupon_id', headerName: 'Coupon ID', type: 'number', width: 100 },
        { field: 'code', headerName: 'Coupon Code', width: 260 },
        { field: 'start_date', headerName: 'Start Date', width: 130 },
        { field: 'end_date', headerName: 'End Date', width: 130 },
        { field: 'days_valid', headerName: 'Days Valid', type: 'number', width: 100 },
        { field: 'user', headerName: 'User', width: 200 },
        {
          field: 'actions',
          headerName: 'Actions',
          width: 160,
          renderCell: (params) => {
            return (
                <>
                    <Tooltip title="View Details">
                        <IconButton size="small" onClick={() => handleView(params.row.coupon_id)} style={{marginRight: 10}}>
                            <VisibilityIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit">
                        <IconButton size="small" onClick={() => handleEdit(params.row.coupon_id)} style={{marginRight: 10}}>
                            <EditIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Coupon Activate">
                        <IconButton 
                            size="small" 
                            onClick={() => {
                                if (!params.row.attivato) {
                                    navigateToCouponActivate(params.row.coupon_id);
                                }
                            }} 
                            style={{
                            marginRight: 10,
                            color: params.attivato ? 'gold' : 'lightgrey',
                            }}
                            disabled={params.row.attivato}
                        >
                            <MonetizationOnIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </>
            );
          } 
        }
    ];

    return (
        <div style={{ height: 631, width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                getRowId={(row) => row.coupon_id}
                loading={loading}
                components={{
                    LoadingOverlay: CustomLoadingOverlay,
                }}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 10 },
                    },
                }}
                pageSizeOptions={[10, 20, 50]}
            />
            <UserDetailsDialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} id={selectedId} />
        </div>
    );
}
