import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, TextField, Button, Stack } from '@mui/material';
import Cookies from 'js-cookie';
import './CreditsEdit.scss';

export default function CreditsEdit() {
    const [creditData, setCreditData] = useState({
        id: '',
        user_id: '',
        given_name: '',
        family_name: '',
        crediti_disponibili: 0,
        crediti_totali: 0,
        crediti_impegnati: 0,
        crediti_consumati: 0
    });
    const { id } = useParams();
    const navigate = useNavigate();
    const apiKey = Cookies.get('key');

    useEffect(() => {
        fetchCredit();
    }, [id]);

    async function fetchCredit() {
        try {
            const response = await fetch(`https://admin.omnivisix.io/api/getCredit.php?id=${id}`, {
                headers: {
                    'api_key': apiKey
                }
            });
            const data = await response.json();
            if (data && data.data && data.data.length > 0) {
                const fetchedData = data.data[0];
                setCreditData({
                    ...fetchedData,
                    crediti_disponibili: Number(fetchedData.crediti_disponibili),
                    crediti_totali: Number(fetchedData.crediti_totali),
                    crediti_impegnati: Number(fetchedData.crediti_impegnati),
                    crediti_consumati: Number(fetchedData.crediti_consumati),
                });
            }
        } catch (error) {
            console.error("Error while fetching credits data:", error);
        }
    }

    const handleIncrement = () => {
        setCreditData((prevData) => ({
            ...prevData,
            crediti_disponibili: prevData.crediti_disponibili + 1,
            crediti_totali: prevData.crediti_totali + 1,
        }));
    };

    const handleDecrement = () => {
        setCreditData((prevData) => ({
            ...prevData,
            crediti_disponibili: Math.max(prevData.crediti_disponibili - 1, 0),
            crediti_totali: Math.max(prevData.crediti_totali - 1, 0),
        }));
    };

    const handleSubmit = async () => {
        if (!creditData) return;

        const formData = new FormData();
        formData.append('id', creditData.id.toString());
        formData.append('available', creditData.crediti_disponibili.toString());
        formData.append('total', creditData.crediti_totali.toString());

        try {
            const response = await fetch('https://admin.omnivisix.io/api/editCredits.php', {
                headers: {
                    'api_key': apiKey
                },
                method: 'POST',
                body: formData,
            });
            const result = await response.json();

            if (result.success === true || result.success === 'true') {
                navigate("/credits");
                console.log("Data saved successfully");
            } else {
                console.error("Failed to save data");
            }
        } catch (error) {
            console.error("Error saving data:", error);
        }
    };

    return (
        <div className="credits__wrapper">
            <h2 className="credits__title">Credits Edit</h2>
            <div className="credits__info-wrapper">
                <Stack spacing={3} sx={{ width: 800 }}>
                    <Box className='credits__inputs-wrapper' sx={{ display: 'flex', gap: 5 }}>
                        <TextField 
                            sx={{ width: 200 }}
                            className="form__input" 
                            id="id" 
                            label="ID" 
                            variant="outlined" 
                            disabled 
                            value={creditData ? creditData.id : ''} 
                        />
                        <TextField 
                            sx={{ width: 450 }}
                            className="form__input" 
                            id="user_id" 
                            label="User ID" 
                            variant="outlined" 
                            disabled 
                            value={creditData ? creditData.user_id : ''} 
                        />
                    </Box>
                    <Box className='credits__inputs-wrapper' sx={{ display: 'flex', gap: 5 }}>
                        <TextField 
                            sx={{ width: 200 }}
                            className="form__input" 
                            id="first_name" 
                            label="First Name" 
                            variant="outlined" 
                            disabled 
                            value={creditData ? creditData.given_name : ''} 
                        />
                        <TextField 
                            sx={{ width: 200 }}
                            className="form__input" 
                            id="last_name" 
                            label="Last Name" 
                            variant="outlined" 
                            disabled 
                            value={creditData ? creditData.family_name : ''} 
                        />
                    </Box>
                </Stack>
            </div>
            <div className='credits__form-wrapper'>
                <div className='credits__inputs-wrapper credits__inputs-wrapper--btn'>
                    <TextField 
                        sx={{ width: 200 }}
                        className="form__input credits__input" 
                        id="available" 
                        label="Available" 
                        variant="outlined"
                        value={creditData ? creditData.crediti_disponibili : 0}
                        onChange={(e) => setCreditData({ ...creditData, crediti_disponibili: Number(e.target.value) })}
                    />
                    <div className='credits__input--btn-group'>
                        <Button variant="contained" color="primary" onClick={handleDecrement}>
                            -
                        </Button>
                        <Button variant="contained" color="primary" onClick={handleIncrement}>
                            +
                        </Button>
                    </div>
                </div>
                <div className='credits__inputs-wrapper'>
                    <TextField 
                        sx={{ width: 200 }}
                        className="form__input credits__input" 
                        id="total" 
                        label="Total" 
                        variant="outlined" 
                        disabled 
                        value={creditData ? creditData.crediti_totali : ''} 
                    />
                </div>
                <div className='credits__inputs-wrapper'>
                    <TextField 
                        sx={{ width: 200 }}
                        className="form__input credits__input" 
                        id="in_progress" 
                        label="In progress" 
                        variant="outlined" 
                        disabled 
                        value={creditData ? creditData.crediti_impegnati : ''} 
                    />
                </div>
                <div className='credits__inputs-wrapper'>
                    <TextField 
                        sx={{ width: 200 }}
                        className="form__input credits__input" 
                        id="used" 
                        label="Used" 
                        variant="outlined" 
                        disabled 
                        value={creditData ? creditData.crediti_consumati : ''} 
                    />
                </div>
            </div>
            <Button 
                variant="contained" 
                color="primary"
                onClick={() => handleSubmit()}
            >
                save
            </Button>
        </div>
    );
}
