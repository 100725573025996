import * as React from 'react';
// import { Link } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';


import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Cookies from 'js-cookie';

export default function UserList() {

    const [rows, setRows] = React.useState([]);
    const apiKey = Cookies.get('key');

    const navigate = useNavigate();



    React.useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://admin.omnivisix.io/api/user/listUsers.php', {
                    headers: {
                        'api_key': apiKey
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    setRows(data.data);
                    console.log(data)
                } else {
                    console.error('Failed to fetch data:', response.statusText);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);
    

    const handleEdit = (id) => {
        navigate(`/admin/users-edit/${id}`);
    };

    const handleDelete = (id) => {
        fetch(`https://admin.omnivisix.io/api/user/deleteUser.php?id=${id}`, {
            headers: {
                'api_key': apiKey
            },
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ id })
        })
        .then(response => response.json())
        .then(data => {
            console.log(data)
            if (data.data === 1) {
                const newRows = rows.filter(row => row.id !== id);
                setRows(newRows);
            }
        });
    };


    function DelButtonModal({ id }) {
        const [open, setOpen] = React.useState(false);
        const theme = useTheme();
        const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
        
        const handleClickOpen = () => {
            setOpen(true);
        };
        
        const handleClose = () => {
            setOpen(false);
        };

        function combinedClickHandler() {
            handleClose();
            handleDelete(id);
        }
    
        return (
            <div>
                <Button variant="outlined" size="small" color="error" onClick={handleClickOpen}>
                    Delete
                </Button>
                <Dialog
                    fullScreen={fullScreen}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">
                    {"Are you sure?"}
                    </DialogTitle>
                    <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Disagree
                    </Button>
                    <Button onClick={combinedClickHandler} autoFocus>
                        Agree
                    </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
  
    const columns = [
        { field: 'id', headerName: 'ID', type: 'number', width: 80 },
        { field: 'name', headerName: 'Name', width: 100 },
        { field: 'email', headerName: 'Email', width: 200 },
        { field: 'role', headerName: 'Role', width: 100 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 180,
            renderCell: (params) => {
                return (
                <>
                    <Button size="small" variant="outlined" style={{marginRight: 15}} onClick={() => handleEdit(params.row.id)}>Edit</Button>
                    <DelButtonModal id={params.row.id} />
                </>
                );
            }
        }
    ];
  
  
    return (
        <div style={{ height: 631, width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                },
                }}
                pageSizeOptions={[10, 20, 50]}
                // checkboxSelection
            />
        </div>
      );
  };