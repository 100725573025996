import React from 'react';
import { Dialog, DialogContent, DialogActions, TextField, Stack, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './ReportsDetailsDialog.scss';

export default function ReportsDetailsDialog({ open, onClose, log }) {
    // Parse the geoiplookup JSON if log and geoiplookup exist
    const geoiplookupData = log && log.geoiplookup ? JSON.parse(log.geoiplookup) : {};

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="user-details-dialog" sx={{ '& .MuiDialog-paper': { maxWidth: '800px', width: '100%' } }}>
            <DialogActions>
                <IconButton onClick={onClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
            </DialogActions>
            <DialogContent className="popup-form__wrapper1">
                {log && (
                    <Stack spacing={2} className="popup-form__wrapper">
                        <div className='inputs__wrapper'>
                            <TextField id="id" label="ID" className="popup-form__input" variant="outlined" value={log.id || ' '} disabled />
                        </div>
                        <div className='inputs__wrapper'>
                            <TextField id="user_id" label="User ID" className="popup-form__input" variant="outlined" value={log.user_id || ' '} disabled />
                        </div>
                        <div className='inputs__wrapper'>
                            <TextField id="address" label="Address" className="popup-form__input" variant="outlined" value={log.address || ' '} disabled />
                        </div>
                        <div className='inputs__wrapper'>
                            <TextField id="action" label="Action" className="popup-form__input" variant="outlined" value={log.action || ' '} disabled />
                        </div>
                        <div className='inputs__wrapper'>
                            <TextField id="timestamp" label="Timestamp" className="popup-form__input" variant="outlined" value={log.timestamp || ' '} disabled />
                        </div>
                        <div className='inputs__wrapper'>
                            <TextField id="url" label="URL" className="popup-form__input" variant="outlined" value={log.url || ' '} disabled />
                        </div>
                        <h2>geoiplookup</h2>
                        {geoiplookupData && Object.keys(geoiplookupData).map((key) => (
                            <div className='inputs__wrapper' key={key}>
                                <TextField id={key} label={key.charAt(0).toUpperCase() + key.slice(1)} className="popup-form__input" variant="outlined" value={geoiplookupData[key] || ' '} disabled />
                            </div>
                        ))}
                    </Stack>
                )}
            </DialogContent>
        </Dialog>
    ); 
}
