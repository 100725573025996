import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Cookies from 'js-cookie';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useParams, useLocation } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';

const StyledGridOverlay = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
});

const CustomLoadingOverlay = () => {
    return (
        <StyledGridOverlay>
            <CircularProgress />
        </StyledGridOverlay>
    );
};

export default function ScanList() {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useState('');
    const apiKey = Cookies.get('key');
    const { userId: paramUserId } = useParams();
    const location = useLocation();
    const userName = location.state?.userName || '';

    useEffect(() => {
        const fetchUserData = async () => {
            setLoading(true);
            if (paramUserId) {
                await fetchScans(paramUserId);
            } else {
                await fetchUsers();
            }
            setLoading(false);
        };
    
        fetchUserData();
    }, [paramUserId]);

    useEffect(() => {
        const userIdFromState = location.state?.userId;
        if (paramUserId) {
            setUserId(paramUserId);
        } else if (userIdFromState) {
            setUserId(userIdFromState);
        }
    }, [paramUserId, location.state]);

    const fetchUsers = async () => {
        try {
            const response = await fetch('https://admin.omnivisix.io/api/listScans.php', {
                headers: {
                    'api_key': apiKey
                }
            });
            const data = await response.json();
            if (data && data.data) {
                const usersWithId = data.data.map((user) => {
                    return {
                        ...user,
                        user: `${user.given_name ?? ''} ${user.family_name ?? ''}`.trim(),
                        id: user.id || Math.random().toString(36).substr(2, 9)
                    };
                });
                setRows(usersWithId);
            }
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    const fetchScans = async (userId) => {
        try {
            const response = await fetch(`https://admin.omnivisix.io/api/listScanByUser.php?user_id=${userId}`, {
                headers: {
                    'api_key': apiKey
                }
            });
            const data = await response.json();
            if (data && data.data) {
                const updatedScans = data.data.map((item) => ({
                    ...item,
                    user: userName
                }));
                setRows(updatedScans);
            }
        } catch (error) {
            console.error('Error fetching scans:', error);
        }
    };

    const columns = [
        { field: 'timestamp', headerName: 'Date and Time', width: 240 },
        { field: 'user', headerName: 'User', width: 200 },
        { field: 'device_type', headerName: 'Type', width: 180 },
        { field: 'device_os', headerName: 'OS', width: 150 },
        { field: 'status', headerName: 'Status', width: 150 },
        {
            field: 'pdf',
            headerName: 'Pdf',
            width: 60,
            renderCell: (params) => (
                <Tooltip title="View PDF">
                    <IconButton size="small" onClick={() => window.open(`${params.row.pdf}`, '_blank')}>
                        <InsertDriveFileIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            )
        },
        {
            field: 'view_html',
            headerName: 'HTML',
            width: 90,
            renderCell: (params) => (
                <Tooltip title="View HTML">
                    <IconButton size="small" onClick={() => window.open(`${params.row.html}`, '_blank')}>
                        <VisibilityIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            )
        },
    ];

    return (
        <div style={{ height: 631, width: '100%' }}>
            <h2 className='scans-form__title'>
                {userName ? `Scan Report for ${userName}` : 'Scans Reports'}
            </h2>
            <DataGrid
                rows={rows}
                columns={columns}
                getRowId={(row) => row.id}
                loading={loading}
                components={{
                    LoadingOverlay: CustomLoadingOverlay,
                }}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 10 },
                    },
                }}
                pageSizeOptions={[10, 20, 50]}
            />
        </div>
    );
}
