import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import UserDetailsDialog from "../UserDetailsDialog/UserDetailsDialog";
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PageviewIcon from '@mui/icons-material/Pageview';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Tooltip from '@mui/material/Tooltip';
import Cookies from 'js-cookie';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';

const StyledGridOverlay = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
});

const CustomLoadingOverlay = () => {
    return (
        <StyledGridOverlay>
            <CircularProgress />
        </StyledGridOverlay>
    );
};

export default function UserMain() {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const apiKey = Cookies.get('key');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await fetch('https://admin.omnivisix.io/api/listAccounts.php', {
                    headers: {
                        'api_key': apiKey
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    setRows(data.data);
                    console.log(data);
                } else {
                    console.error('Failed to fetch data:', response.statusText);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [apiKey]);

    const handleMore = (id) => {
        setSelectedId(id);
        setIsDialogOpen(true);
    };

    const handleEdit = (id) => {
        navigate(`/user-edit/${id}`);
    };

    const navigateToUserScans = (userId, userName) => {
        navigate(`/scans/${userId}`, { state: { userName } });
    };

    const navigateToCreditsEdit = (userId) => {
        navigate(`/credits-edit/${userId}`);
    };

    const navigateToCreditsAdd = (id) => {
        navigate(`/credits-add/${id}`);
    };

    const navigateToUserLogs = (userId) => {
        navigate(`/reports/log-files/${userId}`);
    };

    const navigateToUserOrders = (userId) => {
        navigate(`/reports/orders/${userId}`);
    };

    const columns = [
        { field: 'id', headerName: 'ID', type: 'number', width: 30 },
        { field: 'user_id', headerName: 'User ID', type: 'number', width: 130 },
        { field: 'given_name', headerName: 'Firstname', width: 100 },
        { field: 'family_name', headerName: 'Lastname', width: 100 },
        { field: 'email', headerName: 'Email', width: 160 },
        { field: 'Nickname', headerName: 'Nickname', width: 80 },
        { field: 'tipo_utente', headerName: 'Type', width: 100 },
        { field: 'lingua', headerName: 'Lang', width: 60 },
        { field: 'nazione', headerName: 'Nation', width: 60 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 250,
            renderCell: (params) => {
                return (
                    <>
                        <Tooltip title="View Details">
                            <IconButton size="small" onClick={() => handleMore(params.row.id)} style={{ marginRight: 10 }}>
                                <VisibilityIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Edit">
                            <IconButton size="small" onClick={() => handleEdit(params.row.id)} style={{ marginRight: 10 }}>
                                <EditIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="View Scans">
                            <IconButton size="small" onClick={() => navigateToUserScans(params.row.user_id, `${params.row.given_name} ${params.row.family_name}`)} style={{ marginRight: 10 }}>
                                <PageviewIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={params.row.credit_id ? "Edit Credits" : "Add Credits"}>
                            <IconButton
                                size="small"
                                onClick={() => {
                                    if (params.row.credit_id) {
                                        navigateToCreditsEdit(params.row.user_id);
                                    } else {
                                        navigateToCreditsAdd(params.row.id);
                                    }
                                }}
                                style={{
                                    marginRight: 10,
                                    color: params.row.credit_id ? 'gold' : 'lightgrey',
                                }}
                            >
                                <MonetizationOnIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="View Logs">
                            <IconButton size="small" onClick={() => navigateToUserLogs(params.row.user_id)} style={{ marginRight: 10 }}>
                                <AssignmentIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="View Orders">
                            <IconButton size="small" onClick={() => navigateToUserOrders(params.row.user_id)} style={{ marginRight: 10 }}>
                                <ShoppingCartIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </>
                );
            }
        }
    ];

    return (
        <div style={{ height: 631, width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                loading={loading}
                components={{
                    LoadingOverlay: CustomLoadingOverlay,
                }}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 10 },
                    },
                }}
                pageSizeOptions={[10, 20, 50]}
            />
            <UserDetailsDialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} id={selectedId} />
        </div>
    );
}
