import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';

import LoginForm from "../loginForm/LoginForm";
import NavigationMenu from "../navigationMenu/NavigationMenu";
import UserMain from "../UserMain/UserMain";
import UserDetailsEdit from "../UserDetailsEdit/UserDetailsEdit";
import Credits from "../Credits/Credits"
import CreditsEdit from "../CreditsEdit/CreditsEdit"
import CreditsAdd from "../CreditsAdd/CreditsAdd"
import Scans from "../Scans/Scans"
import CouponList from '../CouponList/CouponList';
import CouponEdit from '../CouponEdit/CouponEdit';
import CouponView from '../CouponView/CouponView';
import CouponActivate from '../CouponActivate/CouponActivate';
import UserList from '../UserList/UserList';
import UserEdit from '../UserEdit/UserEdit';
import UserAdd from '../UserAdd/UserAdd';
import ReportsOrders from '../ReportsOrders/ReportsOrders';
import ReportsLogFiles from '../ReportsLogFiles/ReportsLogFiles';







const Content = ({ isAuthenticated, onLogout, onLoginSuccess }) => {


    const location = useLocation();
    // Navigation render logic

    const renderNavigationMenu = () => {
        if (location.pathname.includes('/login')) {
            return <div></div>;
        } else {
            return <NavigationMenu onLogout={onLogout} />;
        }
    };



    // Routes and redirection

    return (
        <div>
            {/* <NavigationMenu onLogout={onLogout} /> */}
            { isAuthenticated && renderNavigationMenu() }
            <Routes>
                <Route path="/" element={ <Navigate to="/user" replace /> } />
                <Route path="/login" element={ isAuthenticated ? <Navigate to="/user" /> : <LoginForm isAuthenticated={isAuthenticated} onLoginSuccess={onLoginSuccess} /> } />

                <Route path="/user" element={ isAuthenticated ? <UserMain /> : <Navigate to="/login" /> } />
                <Route path="/user-edit/:id" element={ isAuthenticated ? <UserDetailsEdit /> : <Navigate to="/login" /> } />
                <Route path="/scans" element={ isAuthenticated ? <Scans /> : <Navigate to="/login" /> } />
                <Route path="/scans/:userId" element={ isAuthenticated ? <Scans /> : <Navigate to="/login" /> } />
                <Route path="/coupon/list" element={ isAuthenticated ? <CouponList /> : <Navigate to="/login" /> } />
                <Route path="/coupon/add" element={ isAuthenticated ? <CouponEdit /> : <Navigate to="/login" /> } />
                <Route path="/coupon/edit" element={ isAuthenticated ? <CouponEdit /> : <Navigate to="/login" /> } />
                <Route path="/coupon/edit/:id" element={ isAuthenticated ? <CouponEdit /> : <Navigate to="/login" /> } />
                <Route path="/coupon/view/:id" element={ isAuthenticated ? <CouponView /> : <Navigate to="/login" /> } />
                <Route path="/coupon-activate" element={ isAuthenticated ? <CouponActivate /> : <Navigate to="/login" /> } />
                <Route path="/coupon-activate/:id" element={ isAuthenticated ? <CouponActivate /> : <Navigate to="/login" /> } />
                <Route path="/credits" element={ isAuthenticated ? <Credits /> : <Navigate to="/login" /> } />
                <Route path="/credits-edit/:id" element={ isAuthenticated ? <CreditsEdit /> : <Navigate to="/login" /> } />
                <Route path="/credits-add/:id" element={ isAuthenticated ? <CreditsAdd /> : <Navigate to="/login" /> } />
                <Route path="/admin/users-list" element={ isAuthenticated ? <UserList /> : <Navigate to="/login" /> } />
                <Route path="/admin/users-edit/:id" element={ isAuthenticated ? <UserEdit /> : <Navigate to="/login" /> } />
                <Route path="/admin/users-add" element={ isAuthenticated ? <UserAdd /> : <Navigate to="/login" /> } />
                <Route path="/reports/orders" element={ isAuthenticated ? <ReportsOrders /> : <Navigate to="/login" /> } />
                <Route path="/reports/orders/:id" element={ isAuthenticated ? <ReportsOrders /> : <Navigate to="/login" /> } />
                <Route path="/reports/log-files" element={ isAuthenticated ? <ReportsLogFiles /> : <Navigate to="/login" /> } />
                <Route path="/reports/log-files/:id" element={ isAuthenticated ? <ReportsLogFiles /> : <Navigate to="/login" /> } />
            </Routes>
        </div>
    );
};


const App = () => {


    const [isAuthenticated, setIsAuthenticated] = useState(!!Cookies.get('isAuthenticated')); 



    const handleLogoutSuccess = () => {
        setIsAuthenticated(false);
        Cookies.remove('isAuthenticated');
        Cookies.remove('username');
        Cookies.remove('role');
    };
    const handleLoginSuccess = () => {
        setIsAuthenticated(true);
        Cookies.set('isAuthenticated', true, { expires: 7 });
    };


    return (
        <Router>
            <div className="app">
                <Content 
                  isAuthenticated={isAuthenticated} 
                  onLogout={handleLogoutSuccess} 
                  onLoginSuccess={handleLoginSuccess}
                />
            </div>
        </Router>
    );
};

export default App;
