import React, { useState, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Cookies from 'js-cookie';
// import { useLocation } from 'react-router-dom';
import { useParams, useNavigate } from 'react-router-dom';
import './UserEdit.scss';




export default function UserEdit() {
    const [userId, setUserId] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("")
    const [isAdmin, setIsAdmin] = useState(true);
    const { id } = useParams();
    const navigate = useNavigate();
    const apiKey = Cookies.get('key');

    useEffect(() => {
        console.log(id);
        const fetchData = async () => {
            try {
                const response = await fetch(`https://admin.omnivisix.io/api/user/getUser.php?id=${id}`, {
                    headers: {
                        'api_key': apiKey
                    }
                });
                const result = await response.json();

                if (result.errorcode === '200'){
                    const data = result.data;

                    setUserId(data.id);
                    setName(data.name);
                    setEmail(data.email);
                    // setPassword(data.password);
                    setIsAdmin(data.role === 'admin');
                }



            } catch (error) {
                console.error("There was an error fetching the data", error);
            }
        };

        fetchData();
        // eslint-disable-next-line
    }, [id]);
  

    const handleSubmit = async () => {
        const formData = new FormData();
        formData.append('id', id);
        formData.append('name', name);
        formData.append('email', email);
        formData.append('password', password)
        formData.append('role', isAdmin ? 'admin' : 'user');

        console.log(formData);
    
        try {
            const response = await fetch('https://admin.omnivisix.io/api/user/editUser.php', {
                headers: {
                    'api_key': apiKey
                },
                method: 'POST',
                body: formData,
            })
            .then(response => response.json())
            .then(data => {
              console.log(data);
              if (data.success) {
                navigate('/users-list');
              } else {
                // Обработка ошибки
              }
            })
            
            console.log(response);
        } catch (error) {
            console.error("There was an error sending the data", error);
        }
    };
  
    return (
        <Stack>
            <h2 className='header'>User EDIT</h2>
            <div className='inputs-wrapper'>
                <TextField id="userId" label="ID" variant="outlined" value={userId} required onChange={(e) => setUserId(e.target.value)} />
            </div>
            <div className='inputs-wrapper'>
                <TextField id="name" label="NAME" variant="outlined" value={name} required onChange={(e) => setName(e.target.value)} />
            </div>
            <div className='inputs-wrapper'>
                <TextField multiline id="email" label="EMAIL" variant="outlined" value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div className='inputs-wrapper'>
                <TextField multiline id="password" label="NEW PASSWORD" variant="outlined" onChange={(e) => setPassword(e.target.value)} />
            </div>
            <div className='inputs-wrapper'>
                <FormControlLabel control={<Checkbox checked={isAdmin} onChange={() => setIsAdmin(!isAdmin)} />} label="Admin" />
            </div>
            <div className='inputs-wrapper'>
                <Button variant="outlined" onClick={handleSubmit}>save</Button>
            </div>
        </Stack>
    );
}


