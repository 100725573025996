import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogActions, TextField, Stack, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Cookies from 'js-cookie';
import './UserDetailsDialog.scss';

function UserDetailsDialog({ open, onClose, id }) {
    // User data states
    const [userData, setUserData] = useState({
        userId: "",
        name: "",
        secondName: "",
        mail: "",
        nickname: "",
        type: "",
        language: "",
        nation: "",
        phone: "",
        company: "",
        companyAddress: "",
        companyNation: "",
        piva: "",
        companyMail: "",
    });
    const apiKey = Cookies.get('key');


    useEffect(() => {
        if (open) {
            const fetchData = async () => {
                try {
                    const response = await fetch(`https://admin.omnivisix.io/api/getAccount.php?id=${id}`, {
                        headers: {
                            'api_key': apiKey
                        }
                    });
                    const result = await response.json();
    
                    if (result.errorcode === '200' && result.data.length > 0) {
                        const data = result.data[0];
                        setUserData({
                            userId: data.user_id,
                            name: data.given_name,
                            secondName: data.family_name,
                            mail: data.email,
                            nickname: data.nickname, 
                            type: data.tipo_utente,
                            language: data.lingua,
                            nation: data.nazione,
                            phone: data.telefono,
                            company: data.azienda,
                            companyAddress: data.indirizzo_societa,
                            companyNation: data.nazione_societa,
                            piva: data.piva,
                            companyMail: data.email_societa,
                        });
                    } else {
                        console.error(result.error);
                    }
                } catch (error) {
                    console.error("There was an error fetching the data", error);
                }
            };
            fetchData();
        }
    }, [id, open]);

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="user-details-dialog" sx={{ '& .MuiDialog-paper': { maxWidth: '800px', width: '100%' } }}>
            <DialogActions>
                <IconButton onClick={onClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
            </DialogActions>
            <DialogContent className="popup-form__wrapper1">
                <Stack spacing={2} className="popup-form__wrapper">
                    <div className='inputs__wrapper'>
                        <TextField id="id" label="ID" className="popup-form__input" variant="outlined" value={id} disabled />
                    </div>
                    <div className='inputs__wrapper'>
                        <TextField id="user_id" label="User ID" className="popup-form__input" variant="outlined" value={userData.userId} disabled />
                    </div>
                    <div className='inputs__wrapper inputs__wrapper--flex'>
                        <TextField id="name" label="Name" variant="outlined" value={userData.name} disabled />
                        <TextField id="secondname" label="Second Name" variant="outlined" value={userData.secondName} disabled />
                    </div>
                    <div className='inputs__wrapper'>
                        <TextField id="email" label="Email" className="popup-form__input" variant="outlined" value={userData.mail} disabled />
                    </div>
                    <div className='inputs__wrapper'>
                        <TextField id="nickname" label="Nickname" className="popup-form__input" variant="outlined" value={userData.nickname} disabled />
                    </div>
                    <div className='inputs__wrapper'>
                        <TextField id="type" label="Type" className="popup-form__input--half" variant="outlined" value={userData.type} disabled />
                    </div>
                    <div className='inputs__wrapper inputs__wrapper--flex'>
                        <TextField id="language" label="Lang" className="popup-form__input--half" variant="outlined" value={userData.language} disabled />
                        <TextField id="nation" label="Nation" className="popup-form__input--half" variant="outlined" value={userData.nation} disabled />
                    </div>
                    <div className='inputs__wrapper'>
                        <TextField id="phone" label="Telephone" className="popup-form__input" variant="outlined" value={userData.phone} disabled />
                    </div>

                    {userData.type !== 'privato' && (
                        <>
                            <div className='inputs__wrapper'>
                                <TextField id="company" label="Company" className="popup-form__input" variant="outlined" value={userData.company} disabled />
                            </div>
                            <div className='inputs__wrapper'>
                                <TextField id="companyAddress" label="Address" className="popup-form__input" variant="outlined" value={userData.companyAddress} disabled />
                            </div>
                            <div className='inputs__wrapper inputs__wrapper--flex'>
                                <TextField id="companyNation" label="Nation" className="popup-form__input--half" variant="outlined" value={userData.companyNation} disabled />
                                <TextField id="piva" label="Piva" className="popup-form__input--half" variant="outlined" value={userData.piva} disabled />
                            </div>
                            <div className='inputs__wrapper'>
                                <TextField id="companyMail" label="Email" className="popup-form__input" variant="outlined" value={userData.companyMail} disabled />
                            </div>
                        </>
                    )}
                </Stack>
            </DialogContent>
        </Dialog>
    );
}

export default UserDetailsDialog;
