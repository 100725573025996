import * as React from 'react';
import { useEffect, useLocation } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
// import MD5 from 'crypto-js/md5';
import Cookies from 'js-cookie';

import DescriptionAlert from "../alert/Alert";

import { useNavigate } from 'react-router-dom';

import './LoginForm.scss'

export default function InputAdornments({ isAuthenticated, onLoginSuccess }) {
    const [showPassword, setShowPassword] = React.useState(false);
    const [showAlert, setShowAlert] = React.useState(false);
    const loginRef = React.useRef();
    const passwordRef = React.useRef();
    const navigate = useNavigate();
    const apiKey = Cookies.get('key');



    useEffect(() => {
        if (isAuthenticated) {
        navigate('/dashboard');
        }
    }, [isAuthenticated, navigate]);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };



    const handleButtonClick = () => {
        const formData = new FormData();
        formData.append('input_loginname', loginRef.current.value);
        formData.append('input_password', passwordRef.current.value);

        fetch('https://admin.omnivisix.io/api/user/checkLogin.php', {
            method: 'POST',
            body: formData,
        })

        .then(response => response.json())
        .then(data => {
            if (data.success) {
                Cookies.set('username', loginRef.current.value, { expires: 7 });
                Cookies.set('isAuthenticated', true, { expires: 7 });
                Cookies.set('role', data.role, { expires: 7 });
                Cookies.set('key', data.key, { expires: 7 })

                onLoginSuccess();
                navigate('/user');
            } else {
                setShowAlert(true);
            }
        })
        .catch((error) => {console.error('Error:', error); setShowAlert(true);});
    };



    // const handleButtonClick = () => {
    //     const login = loginRef.current.value;
    //     const password = passwordRef.current.value;
    
    //     // Temp login and password
    //     const tempLogin = "admin";
    //     const tempPassword = "m=!?~2{VJSyJ";
    
    //     if (login === tempLogin && password === tempPassword) {
    //         Cookies.set('username', login, { expires: 7 });
    //         Cookies.set('isAuthenticated', true, { expires: 7 });
    //         Cookies.set('role', "admin", { expires: 7 });
    
    //         onLoginSuccess();
    //         navigate('/user');
    //     } else {
    //         setShowAlert(true);
    //     }
    // };

    return (
        <Box 
        className="login__wrapper"
        sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'center', 
            alignItems: 'center', 
            height: '90vh'
        }}
        >
            {showAlert && <DescriptionAlert message="Login failed. Check console for more info" />}
            <div>
                <FormControl sx={{ m: 1, width: '45ch' }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-login">Login</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-login"
                    type="text"
                    label="Login"
                    inputRef={loginRef}
                />
                </FormControl>
            </div>
            <div>
                <FormControl sx={{ m: 1, width: '45ch' }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                    }
                    label="Password"
                    inputRef={passwordRef}
                />
                </FormControl>
            </div>
            <div>
                <Stack direction="row" spacing={2}>
                <Button variant="contained" onClick={handleButtonClick}>Submit</Button>
                </Stack>
            </div>
        </Box>
    );
}
