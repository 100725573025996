import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';

const StyledGridOverlay = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
});

const CustomLoadingOverlay = () => {
    return (
        <StyledGridOverlay>
            <CircularProgress />
        </StyledGridOverlay>
    );
};

export default function Credits() {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const apiKey = Cookies.get('key');

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await fetch('https://admin.omnivisix.io/api/listCredits.php', {
                    headers: {
                        'api_key': apiKey
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    setRows(data.data);
                } else {
                    console.error('Failed to fetch data:', response.statusText);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [apiKey]);

    const handleEdit = (userId) => {
        navigate(`/credits-edit/${userId}`);
    };

    const columns = [
        { field: 'id', headerName: 'ID', type: 'number', width: 30 },
        { field: 'user_id', headerName: 'User ID', type: 'number', width: 260 },
        { field: 'given_name', headerName: 'Firstname', width: 150 },
        { field: 'family_name', headerName: 'Lastname', width: 150 },
        { field: 'crediti_disponibili', headerName: 'Available', width: 80 },
        { field: 'crediti_totali', headerName: 'Total', width: 80 },
        { field: 'crediti_impegnati', headerName: 'In progress', width: 100 },
        { field: 'crediti_consumati', headerName: 'Used', width: 100 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 100,
            renderCell: (params) => {
                return (
                    <Tooltip title="Edit">
                        <IconButton size="small" onClick={() => handleEdit(params.row.user_id)} style={{ marginRight: 10 }}>
                            <EditIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                );
            }
        }
    ];

    return (
        <div style={{ height: 631, width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                loading={loading}
                components={{
                    LoadingOverlay: CustomLoadingOverlay,
                }}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 10 },
                    },
                }}
                pageSizeOptions={[10, 20, 50]}
            />
        </div>
    );
}
