import * as React from 'react';
import { useParams } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import Cookies from 'js-cookie';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { green, red } from '@mui/material/colors';
import ReportsOrdersDialog from '../ReportsOrdersDialog/ReportsOrdersDialog';

export default function ReportsOrders() {
    const { id } = useParams();
    const [rows, setRows] = React.useState([]);
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const [selectedLog, setSelectedLog] = React.useState(null);
    const apiKey = Cookies.get('key');

    React.useEffect(() => {
        const fetchData = async () => {
            try {
                const url = id 
                    ? `https://admin.omnivisix.io/api/listOrdersByUser.php?id=${id}` 
                    : 'https://admin.omnivisix.io/api/listOrders.php';

                const response = await fetch(url, {
                    headers: {
                        'api_key': apiKey
                    }
                });

                if (response.ok) {
                    const data = await response.json();
                    setRows(data.data);
                } else {
                    console.error('Failed to fetch data:', response.statusText);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [apiKey, id]);

    const handleMore = async (id) => {
        try {
            const response = await fetch(`https://admin.omnivisix.io/api/getOrder.php?id=${id}`, {
                headers: {
                    'api_key': apiKey
                }
            });

            if (response.ok) {
                const data = await response.json();
                setSelectedLog(data.data);
                setIsDialogOpen(true);
            } else {
                console.error('Failed to fetch order details:', response.statusText);
            }
        } catch (error) {
            console.error('Error fetching order details:', error);
        }
    };

    const columns = [
        { field: 'id', headerName: 'ID', type: 'number', width: 100 },
        { field: 'user_id', headerName: 'User ID', width: 240 },
        { field: 'OrdineID', headerName: 'Order ID', width: 240 },
        { field: 'OggettiComprati', headerName: 'Items', width: 130 },
        { field: 'Totale', headerName: 'Total', type: 'number', width: 100 },
        { 
            field: 'DataOrdineFine', 
            headerName: 'Order Completed', 
            width: 160,
            renderCell: (params) => (
                params.value ? 
                    <CheckCircleIcon style={{ color: green[500] }} /> : 
                    <CancelIcon style={{ color: red[500] }} />
            )
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 100,
            renderCell: (params) => (
              <Tooltip title="View Details">
                  <IconButton size="small" onClick={() => handleMore(params.row.id)}>
                      <VisibilityIcon fontSize="small" />
                  </IconButton>
              </Tooltip>
            )
        }
    ];

    return (
        <div style={{ height: 631, width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                getRowId={(row) => row.id}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 10 },
                    },
                }}
                pageSizeOptions={[10, 20, 50]}
            />
            {selectedLog && (
                <ReportsOrdersDialog 
                    open={isDialogOpen} 
                    onClose={() => setIsDialogOpen(false)} 
                    log={selectedLog} 
                />
            )}
        </div>
    );
}
