import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FormControl, FormControlLabel, Stack, TextField, Autocomplete, Button, Radio, RadioGroup } from '@mui/material';
import Cookies from 'js-cookie';
import '../UserDetailsEdit/UserDetailsEdit.scss';


export default function UserDetailsEdit() {

    const [userData, setUserData] = useState({
        userId: "",
        name: "",
        secondName: "",
        mail: "",
        nickname: "",
        type: "",
        language: "",
        nation: "",
        phone: "",
        company: "",
        companyAddress: "",
        companyNation: "",
        piva: "",
        companyMail: "",
    });

    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const { id } = useParams();
    const apiKey = Cookies.get('key');

    const validateDropdown = (value, field) => {
        if (!value) return `${field} is required.`;
        return "";
    };

    const validateTextInput = (value, field) => {
        if (!value.trim()) return `${field} is required.`;
        return "";
    };

    const languageOptions = Object.entries(languages).map(([code, name]) => ({
        code: code.toUpperCase(),
        label: name,
    }));

    const handleLanguageChange = (event, newValue) => {
        setUserData({ ...userData, language: newValue ? newValue.code.toUpperCase() : '' });
    };

    useEffect(() => {
        const fetchData = async () => {
            if (id) {
                try {
                    const response = await fetch(`https://admin.omnivisix.io/api/getAccount.php?id=${id}`, {
                      headers: {
                          'api_key': apiKey
                      }
                  });
                    const result = await response.json();
        
                    if (result.errorcode === '200' && result.data.length > 0) {
                        const data = result.data[0];
                        setUserData({
                            userId: data.user_id || "",
                            name: data.given_name || "",
                            secondName: data.family_name || "",
                            mail: data.email || "",
                            nickname: data.nickname || "",
                            type: data.tipo_utente || "privato",
                            language: data.lingua ? data.lingua.toUpperCase() : "",
                            nation: data.nazione || "",
                            phone: data.telefono || "",
                            company: data.azienda || "",
                            companyAddress: data.indirizzo_societa || "",
                            companyNation: data.nazione_societa || "",
                            piva: data.piva || "",
                            companyMail: data.email_societa || "",
                        });
                    } else {
                        console.error(result.error);
                    }
                } catch (error) {
                    console.error("There was an error fetching the data", error);
                }
            }
        };
  
        fetchData();
  }, [id]);


    const handleCancel = () => {
        navigate("/user");
    };

    const handleSave = async () => {
        const newErrors = {
            name: validateTextInput(userData.name, 'Name'),
            secondName: validateTextInput(userData.secondName, 'Second Name'),
            mail: validateTextInput(userData.mail, 'Email'),
            nickname: validateTextInput(userData.nickname, 'Nickname'),
            language: validateDropdown(userData.language, 'Language'),
            nation: validateDropdown(userData.nation, 'Nation'),
            type: validateDropdown(userData.type, 'Type'),
        };

        setErrors(newErrors);

        if (Object.values(newErrors).some(error => error !== "")) {
            // If any field fails validation, stop the save operation.
            return;
        }

        // const body = {
        //     ...userData,
        //     lingua: userData.language,
        //     nazione: userData.nation,
        // };

        const formData = new FormData();
        formData.append("id", id);
        Object.entries(userData).forEach(([key, value]) => {
            formData.append(key, value);
        });

        try {
            const response = await fetch('https://admin.omnivisix.io/api/editAccount.php', {
                headers: {
                  'api_key': apiKey
                },
                method: 'POST',
                body: formData,
            });

            const result = await response.json();

            if (result.success === true || result.success === 'true') {
                // Redirect to main page after sending data
                console.log("Data saved successfully");
                navigate("/user");
            } else {
                console.error("Failed to save data");
            }
        } catch (error) {
            console.error("Error saving data:", error);
        }
    };


    return (
        <Stack spacing={3} sx={{ width: 800 }}>
            <h2 className='form__title'>User EDIT</h2>
            <div className='form__inputs-wrapper'>
                <TextField id="id" label="ID" variant="outlined" value={id} sx={{ width: 120 }} disabled />
                <TextField id="user_id" label="User ID" variant="outlined" value={userData.userId} sx={{ width: 420 }} disabled />
            </div>
            <div className='form__inputs-wrapper'>
            <FormControl component="fieldset">
                <RadioGroup
                    row
                    aria-label="user-type"
                    name="user-type-group"
                    value={userData.type}
                    onChange={(e) => setUserData({ ...userData, type: e.target.value })}
                >
                    <FormControlLabel value="privato" control={<Radio />} label="Privato" />
                    <FormControlLabel value="societa" control={<Radio />} label="Societa" />
                </RadioGroup>
            </FormControl>
            </div>
            <div className='form__inputs-wrapper'>
                <TextField
                    error={!!errors.name}
                    helperText={errors.name}
                    id="name"
                    label="Name"
                    variant="outlined"
                    value={userData.name}
                    onChange={e => setUserData({ ...userData, name: e.target.value })}
                />
                <TextField 
                    error={!!errors.secondName}
                    helperText={errors.secondName}
                    id="secondname" 
                    label="Second Name" 
                    variant="outlined" 
                    value={userData.secondName}
                    onChange={e => setUserData({ ...userData, secondName: e.target.value })}
                />
            </div>
            <div className='form__inputs-wrapper'>
                <TextField 
                    error={!!errors.mail}
                    helperText={errors.mail}
                    className="form__input" 
                    id="email" label="Email" 
                    variant="outlined" 
                    value={userData.mail} 
                    onChange={e => setUserData({ ...userData, mail: e.target.value })}
                />
            </div>
            <div className='form__inputs-wrapper'>
                <TextField 
                    error={!!errors.nickname}
                    helperText={errors.nickname}
                    className="form__input" 
                    id="nickname" label="Nickname" 
                    variant="outlined" 
                    value={userData.nickname} 
                    onChange={e => setUserData({ ...userData, nickname: e.target.value })}
                />
            </div>
            {/* <div className='form__inputs-wrapper'>
                <FormControl fullWidth>
                    <InputLabel id="type-label">Type</InputLabel>
                    <Select
                        labelId="type-label"
                        id="type"
                        value={userData.type}
                        label="Type"
                        onChange={(e) => setUserData({ ...userData, type: e.target.value })}
                    >
                        <MenuItem value={"privato"}>Privato</MenuItem>
                        <MenuItem value={"societa"}>Societa</MenuItem>
                    </Select>
                </FormControl>
            </div> */}
            <div className='form__inputs-wrapper'>
                <Autocomplete
                    className="form__input form__input--small"
                    id="nation-select"
                    options={countries}
                    getOptionLabel={(option) => option.label}
                    value={countries.find(country => country.code === userData.nation) || null}
                    onChange={(event, newValue) => {
                        setUserData({ ...userData, nation: newValue ? newValue.code : '' });
                        setErrors({ ...errors, nation: '' });
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Nation"
                            error={!!errors.nation}
                            helperText={errors.nation || ' '}
                        />
                    )}
                    isOptionEqualToValue={(option, value) => option.code === value.code}
                />

                <Autocomplete
                    className="form__input form__input--small"
                    id="language-select"
                    options={languageOptions}
                    getOptionLabel={(option) => option.label}
                    value={languageOptions.find(option => option.code === userData.language) || {}}
                    onChange={handleLanguageChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Language"
                            error={!!errors.language}
                            helperText={errors.language || ' '}
                        />
                    )}
                />

                {/* <Autocomplete
                    className="form__input form__input--small"
                    id="language-select"
                    options={countries.reduce((acc, current) => {
                        current.language.split(', ').forEach(lang => {
                            if (!acc.some(item => item.label === lang)) {
                                acc.push({ label: lang });
                            }
                        });
                        return acc;
                    }, [])}
                    getOptionLabel={(option) => option.label}
                    value={{ label: userData.language }}
                    onChange={(event, newValue) => {
                        setUserData({ ...userData, language: newValue ? newValue.label : '' });
                        setErrors({ ...errors, language: '' });
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Language"
                            error={!!errors.language}
                            helperText={errors.language || ' '}
                        />
                    )}
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                /> */}
            </div>
            <div className='form__inputs-wrapper'>
                <TextField 
                    className="form__input" 
                    id="phone" 
                    label="Telephone" 
                    variant="outlined" 
                    value={userData.phone}
                    onChange={e => setUserData({ ...userData, phone: e.target.value })}
                />
            </div>

            {userData.type === "societa" && (
                <>
                    <div className='form__inputs-wrapper'>
                        <TextField 
                            className="form__input" 
                            id="company" 
                            label="Company" 
                            variant="outlined" 
                            value={userData.company} 
                            onChange={e => setUserData({ ...userData, company: e.target.value })}
                        />
                    </div>
                    <div className='form__inputs-wrapper'>
                        <TextField 
                            className="form__input" 
                            id="companyAddress" 
                            label="Address" 
                            variant="outlined" 
                            value={userData.companyAddress}
                            onChange={e => setUserData({ ...userData, companyAddress: e.target.value })}
                        />
                    </div>
                    <div className='form__inputs-wrapper'>
                        <TextField 
                            className="form__input form__input--small" 
                            id="companyNation" 
                            label="Nation" 
                            variant="outlined" 
                            value={userData.companyNation} 
                            onChange={e => setUserData({ ...userData, companyNation: e.target.value })}
                        />
                        <TextField 
                            className="form__input form__input--small" 
                            id="piva" 
                            label="Piva" 
                            variant="outlined" 
                            value={userData.piva} 
                            onChange={e => setUserData({ ...userData, piva: e.target.value })}
                        />
                    </div>
                    <div className='form__inputs-wrapper'>
                        <TextField 
                            className="form__input" 
                            id="companyMail" 
                            label="Email" 
                            variant="outlined" 
                            value={userData.companyMail} 
                            onChange={e => setUserData({ ...userData, companyMail: e.target.value })}
                        />
                    </div>
                </>
            )}

            <Stack direction="row" spacing={2}>
                <Button variant="contained" color="primary" onClick={handleSave}>Save</Button>
                <Button variant="outlined" onClick={handleCancel}>Cancel</Button>
            </Stack>
        </Stack>
    );
}

const languages = {
  "aa": "Afar",
  "ab": "Abkhazian",
  "ae": "Avestan",
  "af": "Afrikaans",
  "ak": "Akan",
  "am": "Amharic",
  "an": "Aragonese",
  "ar": "Arabic",
  "as": "Assamese",
  "av": "Avaric",
  "ay": "Aymara",
  "az": "Azerbaijani",
  "ba": "Bashkir",
  "be": "Belarusian",
  "bg": "Bulgarian",
  "bh": "Bihari languages",
  "bi": "Bislama",
  "bm": "Bambara",
  "bn": "Bengali",
  "bo": "Tibetan",
  "br": "Breton",
  "bs": "Bosnian",
  "ca": "Catalan",
  "ce": "Chechen",
  "ch": "Chamorro",
  "co": "Corsican",
  "cr": "Cree",
  "cs": "Czech",
  "cu": "Church Slavic",
  "cv": "Chuvash",
  "cy": "Welsh",
  "da": "Danish",
  "de": "German",
  "dv": "Maldivian",
  "dz": "Dzongkha",
  "ee": "Ewe",
  "el": "Greek",
  "en": "English",
  "eo": "Esperanto",
  "es": "Spanish",
  "et": "Estonian",
  "eu": "Basque",
  "fa": "Persian",
  "ff": "Fulah",
  "fi": "Finnish",
  "fj": "Fijian",
  "fo": "Faroese",
  "fr": "French",
  "fy": "Western Frisian",
  "ga": "Irish",
  "gd": "Gaelic",
  "gl": "Galician",
  "gn": "Guarani",
  "gu": "Gujarati",
  "gv": "Manx",
  "ha": "Hausa",
  "he": "Hebrew",
  "hi": "Hindi",
  "ho": "Hiri Motu",
  "hr": "Croatian",
  "ht": "Haitian",
  "hu": "Hungarian",
  "hy": "Armenian",
  "hz": "Herero",
  "ia": "Interlingua",
  "id": "Indonesian",
  "ie": "Interlingue",
  "ig": "Igbo",
  "ii": "Sichuan Yi",
  "ik": "Inupiaq",
  "io": "Ido",
  "is": "Icelandic",
  "it": "Italian",
  "iu": "Inuktitut",
  "ja": "Japanese",
  "jv": "Javanese",
  "ka": "Georgian",
  "kg": "Kongo",
  "ki": "Kikuyu",
  "kj": "Kuanyama",
  "kk": "Kazakh",
  "kl": "Kalaallisut",
  "km": "Central Khmer",
  "kn": "Kannada",
  "ko": "Korean",
  "kr": "Kanuri",
  "ks": "Kashmiri",
  "ku": "Kurdish",
  "kv": "Komi",
  "kw": "Cornish",
  "ky": "Kirghiz",
  "la": "Latin",
  "lb": "Luxembourgish",
  "lg": "Ganda",
  "li": "Limburgan",
  "ln": "Lingala",
  "lo": "Lao",
  "lt": "Lithuanian",
  "lu": "Luba-Katanga",
  "lv": "Latvian",
  "mg": "Malagasy",
  "mh": "Marshallese",
  "mi": "Maori",
  "mk": "Macedonian",
  "ml": "Malayalam",
  "mn": "Mongolian",
  "mr": "Marathi",
  "ms": "Malay",
  "mt": "Maltese",
  "my": "Burmese",
  "na": "Nauru",
  "nb": "Norwegian",
  "nd": "North Ndebele",
  "ne": "Nepali",
  "ng": "Ndonga",
  "nl": "Dutch",
  "nn": "Norwegian",
  "no": "Norwegian",
  "nr": "South Ndebele",
  "nv": "Navajo",
  "ny": "Chichewa",
  "oc": "Occitan",
  "oj": "Ojibwa",
  "om": "Oromo",
  "or": "Oriya",
  "os": "Ossetic",
  "pa": "Panjabi",
  "pi": "Pali",
  "pl": "Polish",
  "ps": "Pushto",
  "pt": "Portuguese",
  "qu": "Quechua",
  "rm": "Romansh",
  "rn": "Rundi",
  "ro": "Romanian",
  "ru": "Russian",
  "rw": "Kinyarwanda",
  "sa": "Sanskrit",
  "sc": "Sardinian",
  "sd": "Sindhi",
  "se": "Northern Sami",
  "sg": "Sango",
  "si": "Sinhala",
  "sk": "Slovak",
  "sl": "Slovenian",
  "sm": "Samoan",
  "sn": "Shona",
  "so": "Somali",
  "sq": "Albanian",
  "sr": "Serbian",
  "ss": "Swati",
  "st": "Sotho, Southern",
  "su": "Sundanese",
  "sv": "Swedish",
  "sw": "Swahili",
  "ta": "Tamil",
  "te": "Telugu",
  "tg": "Tajik",
  "th": "Thai",
  "ti": "Tigrinya",
  "tk": "Turkmen",
  "tl": "Tagalog",
  "tn": "Tswana",
  "to": "Tonga",
  "tr": "Turkish",
  "ts": "Tsonga",
  "tt": "Tatar",
  "tw": "Twi",
  "ty": "Tahitian",
  "ug": "Uighur",
  "uk": "Ukrainian",
  "ur": "Urdu",
  "uz": "Uzbek",
  "ve": "Venda",
  "vi": "Vietnamese",
  "vo": "Volapük",
  "wa": "Walloon",
  "wo": "Wolof",
  "xh": "Xhosa",
  "yi": "Yiddish",
  "yo": "Yoruba",
  "za": "Zhuang",
  "zh": "Chinese",
  "zu": "Zulu"
}


const countries = [
    {
      "code": "CY",
      "label": "Cyprus",
      "language": "Greek, Turkish"
    },
    {
      "code": "ER",
      "label": "Eritrea",
      "language": "Arabic, English, Tigrinya"
    },
    {
      "code": "LR",
      "label": "Liberia",
      "language": "English"
    },
    {
      "code": "BM",
      "label": "Bermuda",
      "language": "English"
    },
    {
      "code": "VA",
      "label": "Vatican City",
      "language": "Italian, Latin"
    },
    {
      "code": "CK",
      "label": "Cook Islands",
      "language": "English, Cook Islands Māori"
    },
    {
      "code": "SO",
      "label": "Somalia",
      "language": "Arabic, Somali"
    },
    {
      "code": "ZM",
      "label": "Zambia",
      "language": "English"
    },
    {
      "code": "VE",
      "label": "Venezuela",
      "language": "Spanish"
    },
    {
      "code": "TM",
      "label": "Turkmenistan",
      "language": "Russian, Turkmen"
    },
    {
      "code": "AL",
      "label": "Albania",
      "language": "Albanian"
    },
    {
      "code": "HR",
      "label": "Croatia",
      "language": "Croatian"
    },
    {
      "code": "GB",
      "label": "United Kingdom",
      "language": "English"
    },
    {
      "code": "SD",
      "label": "Sudan",
      "language": "Arabic, English"
    },
    {
      "code": "TL",
      "label": "Timor-Leste",
      "language": "Portuguese, Tetum"
    },
    {
      "code": "CG",
      "label": "Republic of the Congo",
      "language": "French, Kikongo, Lingala"
    },
    {
      "code": "AZ",
      "label": "Azerbaijan",
      "language": "Azerbaijani"
    },
    {
      "code": "KE",
      "label": "Kenya",
      "language": "English, Swahili"
    },
    {
      "code": "AS",
      "label": "American Samoa",
      "language": "English, Samoan"
    },
    {
      "code": "CI",
      "label": "Ivory Coast",
      "language": "French"
    },
    {
      "code": "SN",
      "label": "Senegal",
      "language": "French"
    },
    {
      "code": "VN",
      "label": "Vietnam",
      "language": "Vietnamese"
    },
    {
      "code": "SV",
      "label": "El Salvador",
      "language": "Spanish"
    },
    {
      "code": "AF",
      "label": "Afghanistan",
      "language": "Dari, Pashto, Turkmen"
    },
    {
      "code": "MF",
      "label": "Saint Martin",
      "language": "French"
    },
    {
      "code": "LV",
      "label": "Latvia",
      "language": "Latvian"
    },
    {
      "code": "GT",
      "label": "Guatemala",
      "language": "Spanish"
    },
    {
      "code": "KW",
      "label": "Kuwait",
      "language": "Arabic"
    },
    {
      "code": "ST",
      "label": "São Tomé and Príncipe",
      "language": "Portuguese"
    },
    {
      "code": "KG",
      "label": "Kyrgyzstan",
      "language": "Kyrgyz, Russian"
    },
    {
      "code": "PL",
      "label": "Poland",
      "language": "Polish"
    },
    {
      "code": "GU",
      "label": "Guam",
      "language": "Chamorro, English, Spanish"
    },
    {
      "code": "GH",
      "label": "Ghana",
      "language": "English"
    },
    {
      "code": "LT",
      "label": "Lithuania",
      "language": "Lithuanian"
    },
    {
      "code": "AM",
      "label": "Armenia",
      "language": "Armenian"
    },
    {
      "code": "JE",
      "label": "Jersey",
      "language": "English, French, Jèrriais"
    },
    {
      "code": "GD",
      "label": "Grenada",
      "language": "English"
    },
    {
      "code": "TJ",
      "label": "Tajikistan",
      "language": "Russian, Tajik"
    },
    {
      "code": "ET",
      "label": "Ethiopia",
      "language": "Amharic"
    },
    {
      "code": "EH",
      "label": "Western Sahara",
      "language": "Berber, Hassaniya, Spanish"
    },
    {
      "code": "MA",
      "label": "Morocco",
      "language": "Arabic, Berber"
    },
    {
      "code": "PR",
      "label": "Puerto Rico",
      "language": "English, Spanish"
    },
    {
      "code": "CX",
      "label": "Christmas Island",
      "language": "English"
    },
    {
      "code": "NZ",
      "label": "New Zealand",
      "language": "English, Māori, New Zealand Sign Language"
    },
    {
      "code": "BN",
      "label": "Brunei",
      "language": "Malay"
    },
    {
      "code": "GF",
      "label": "French Guiana",
      "language": "French"
    },
    {
      "code": "NU",
      "label": "Niue",
      "language": "English, Niuean"
    },
    {
      "code": "RO",
      "label": "Romania",
      "language": "Romanian"
    },
    {
      "code": "SJ",
      "label": "Svalbard and Jan Mayen",
      "language": "Norwegian"
    },
    {
      "code": "BY",
      "label": "Belarus",
      "language": "Belarusian, Russian"
    },
    {
      "code": "PA",
      "label": "Panama",
      "language": "Spanish"
    },
    {
      "code": "CM",
      "label": "Cameroon",
      "language": "English, French"
    },
    {
      "code": "CZ",
      "label": "Czechia",
      "language": "Czech, Slovak"
    },
    {
      "code": "BL",
      "label": "Saint Barthélemy",
      "language": "French"
    },
    {
      "code": "GR",
      "label": "Greece",
      "language": "Greek"
    },
    {
      "code": "ML",
      "label": "Mali",
      "language": "French"
    },
    {
      "code": "MQ",
      "label": "Martinique",
      "language": "French"
    },
    {
      "code": "FR",
      "label": "France",
      "language": "French"
    },
    {
      "code": "PK",
      "label": "Pakistan",
      "language": "English, Urdu"
    },
    {
      "code": "PE",
      "label": "Peru",
      "language": "Aymara, Quechua, Spanish"
    },
    {
      "code": "BB",
      "label": "Barbados",
      "language": "English"
    },
    {
      "code": "GL",
      "label": "Greenland",
      "language": "Greenlandic"
    },
    {
      "code": "PM",
      "label": "Saint Pierre and Miquelon",
      "language": "French"
    },
    {
      "code": "TD",
      "label": "Chad",
      "language": "Arabic, French"
    },
    {
      "code": "HU",
      "label": "Hungary",
      "language": "Hungarian"
    },
    {
      "code": "KM",
      "label": "Comoros",
      "language": "Arabic, French, Comorian"
    },
    {
      "code": "BD",
      "label": "Bangladesh",
      "language": "Bengali"
    },
    {
      "code": "TK",
      "label": "Tokelau",
      "language": "English, Samoan, Tokelauan"
    },
    {
      "code": "FJ",
      "label": "Fiji",
      "language": "English, Fijian, Fiji Hindi"
    },
    {
      "code": "CN",
      "label": "China",
      "language": "Chinese"
    },
    {
      "code": "CO",
      "label": "Colombia",
      "language": "Spanish"
    },
    {
      "code": "VG",
      "label": "British Virgin Islands",
      "language": "English"
    },
    {
      "code": "DZ",
      "label": "Algeria",
      "language": "Arabic"
    },
    {
      "code": "MV",
      "label": "Maldives",
      "language": "Maldivian"
    },
    {
      "code": "MY",
      "label": "Malaysia",
      "language": "English, Malay"
    },
    {
      "code": "KY",
      "label": "Cayman Islands",
      "language": "English"
    },
    {
      "code": "ES",
      "label": "Spain",
      "language": "Spanish, Catalan, Basque, Galician"
    },
    {
      "code": "IE",
      "label": "Ireland",
      "language": "English, Irish"
    },
    {
      "code": "EE",
      "label": "Estonia",
      "language": "Estonian"
    },
    {
      "code": "PY",
      "label": "Paraguay",
      "language": "Guaraní, Spanish"
    },
    {
      "code": "UY",
      "label": "Uruguay",
      "language": "Spanish"
    },
    {
      "code": "ZA",
      "label": "South Africa",
      "language": "Afrikaans, English, Southern Ndebele, Northern Sotho, Southern Sotho, Swazi, Tswana, Tsonga, Venda, Xhosa, Zulu"
    },
    {
      "code": "LC",
      "label": "Saint Lucia",
      "language": "English"
    },
    {
      "code": "VU",
      "label": "Vanuatu",
      "language": "Bislama, English, French"
    },
    {
      "code": "FI",
      "label": "Finland",
      "language": "Finnish, Swedish"
    },
    {
      "code": "SE",
      "label": "Sweden",
      "language": "Swedish"
    },
    {
      "code": "IO",
      "label": "British Indian Ocean Territory",
      "language": "English"
    },
    {
      "code": "LB",
      "label": "Lebanon",
      "language": "Arabic, French"
    },
    {
      "code": "US",
      "label": "United States",
      "language": "English"
    },
    {
      "code": "CL",
      "label": "Chile",
      "language": "Spanish"
    },
    {
      "code": "NF",
      "label": "Norfolk Island",
      "language": "English, Norfuk"
    },
    {
      "code": "MS",
      "label": "Montserrat",
      "language": "English"
    },
    {
      "code": "AU",
      "label": "Australia",
      "language": "English"
    },
    {
      "code": "BZ",
      "label": "Belize",
      "language": "Belizean Creole, English, Spanish"
    },
    {
      "code": "GY",
      "label": "Guyana",
      "language": "English"
    },
    {
      "code": "MN",
      "label": "Mongolia",
      "language": "Mongolian"
    },
    {
      "code": "TV",
      "label": "Tuvalu",
      "language": "English, Tuvaluan"
    },
    {
      "code": "DO",
      "label": "Dominican Republic",
      "language": "Spanish"
    },
    {
      "code": "GQ",
      "label": "Equatorial Guinea",
      "language": "French, Portuguese, Spanish"
    },
    {
      "code": "KN",
      "label": "Saint Kitts and Nevis",
      "language": "English"
    },
    {
      "code": "BO",
      "label": "Bolivia",
      "language": "Aymara, Guaraní, Quechua, Spanish"
    },
    {
      "code": "NP",
      "label": "Nepal",
      "language": "Nepali"
    },
    {
      "code": "TF",
      "label": "French Southern and Antarctic Lands",
      "language": "French"
    },
    {
      "code": "TW",
      "label": "Taiwan",
      "language": "Chinese"
    },
    {
      "code": "BJ",
      "label": "Benin",
      "language": "French"
    },
    {
      "code": "BG",
      "label": "Bulgaria",
      "language": "Bulgarian"
    },
    {
      "code": "MD",
      "label": "Moldova",
      "language": "Romanian"
    },
    {
      "code": "IM",
      "label": "Isle of Man",
      "language": "English, Manx"
    },
    {
      "code": "BT",
      "label": "Bhutan",
      "language": "Dzongkha"
    },
    {
      "code": "KH",
      "label": "Cambodia",
      "language": "Khmer"
    },
    {
      "code": "AG",
      "label": "Antigua and Barbuda",
      "language": "English"
    },
    {
      "code": "HT",
      "label": "Haiti",
      "language": "French, Haitian Creole"
    },
    {
      "code": "CV",
      "label": "Cape Verde",
      "language": "Portuguese"
    },
    {
      "code": "GE",
      "label": "Georgia",
      "language": "Georgian"
    },
    {
      "code": "BI",
      "label": "Burundi",
      "language": "French, Kirundi"
    },
    {
      "code": "BS",
      "label": "Bahamas",
      "language": "English"
    },
    {
      "code": "MU",
      "label": "Mauritius",
      "language": "English, French, Mauritian Creole"
    },
    {
      "code": "LY",
      "label": "Libya",
      "language": "Arabic"
    },
    {
      "code": "MW",
      "label": "Malawi",
      "language": "English, Chewa"
    },
    {
      "code": "MX",
      "label": "Mexico",
      "language": "Spanish"
    },
    {
      "code": "SZ",
      "label": "Eswatini",
      "language": "English, Swazi"
    },
    {
      "code": "PG",
      "label": "Papua New Guinea",
      "language": "English, Hiri Motu, Tok Pisin"
    },
    {
      "code": "DM",
      "label": "Dominica",
      "language": "English"
    },
    {
      "code": "LI",
      "label": "Liechtenstein",
      "language": "German"
    },
    {
      "code": "RU",
      "label": "Russia",
      "language": "Russian"
    },
    {
      "code": "IL",
      "label": "Israel",
      "language": "Arabic, Hebrew"
    },
    {
      "code": "SG",
      "label": "Singapore",
      "language": "English, Chinese, Malay, Tamil"
    },
    {
      "code": "UG",
      "label": "Uganda",
      "language": "English, Swahili"
    },
    {
      "code": "SK",
      "label": "Slovakia",
      "language": "Slovak"
    },
    {
      "code": "TO",
      "label": "Tonga",
      "language": "English, Tongan"
    },
    {
      "code": "AE",
      "label": "United Arab Emirates",
      "language": "Arabic"
    },
    {
      "code": "YT",
      "label": "Mayotte",
      "language": "French"
    },
    {
      "code": "SR",
      "label": "Suriname",
      "language": "Dutch"
    },
    {
      "code": "UZ",
      "label": "Uzbekistan",
      "language": "Russian, Uzbek"
    },
    {
      "code": "SA",
      "label": "Saudi Arabia",
      "language": "Arabic"
    },
    {
      "code": "EG",
      "label": "Egypt",
      "language": "Arabic"
    },
    {
      "code": "IT",
      "label": "Italy",
      "language": "Italian"
    },
    {
      "code": "MG",
      "label": "Madagascar",
      "language": "French, Malagasy"
    },
    {
      "code": "NC",
      "label": "New Caledonia",
      "language": "French"
    },
    {
      "code": "CA",
      "label": "Canada",
      "language": "English, French"
    },
    {
      "code": "VI",
      "label": "United States Virgin Islands",
      "language": "English"
    },
    {
      "code": "MH",
      "label": "Marshall Islands",
      "language": "English, Marshallese"
    },
    {
      "code": "MR",
      "label": "Mauritania",
      "language": "Arabic"
    },
    {
      "code": "GM",
      "label": "Gambia",
      "language": "English"
    },
    {
      "code": "TT",
      "label": "Trinidad and Tobago",
      "language": "English"
    },
    {
      "code": "SC",
      "label": "Seychelles",
      "language": "Seychellois Creole, English, French"
    },
    {
      "code": "JP",
      "label": "Japan",
      "language": "Japanese"
    },
    {
      "code": "BR",
      "label": "Brazil",
      "language": "Portuguese"
    },
    {
      "code": "SY",
      "label": "Syria",
      "language": "Arabic"
    },
    {
      "code": "SH",
      "label": "Saint Helena, Ascension and Tristan da Cunha",
      "language": "English"
    },
    {
      "code": "TZ",
      "label": "Tanzania",
      "language": "English, Swahili"
    },
    {
      "code": "AD",
      "label": "Andorra",
      "language": "Catalan"
    },
    {
      "code": "IR",
      "label": "Iran",
      "language": "Persian (Farsi)"
    },
    {
      "code": "TG",
      "label": "Togo",
      "language": "French"
    },
    {
      "code": "MT",
      "label": "Malta",
      "language": "English, Maltese"
    },
    {
      "code": "KR",
      "label": "South Korea",
      "language": "Korean"
    },
    {
      "code": "WS",
      "label": "Samoa",
      "language": "English, Samoan"
    },
    {
      "code": "DE",
      "label": "Germany",
      "language": "German"
    },
    {
      "code": "NE",
      "label": "Niger",
      "language": "French"
    },
    {
      "code": "BV",
      "label": "Bouvet Island",
      "language": "Norwegian"
    },
    {
      "code": "JM",
      "label": "Jamaica",
      "language": "English, Jamaican Patois"
    },
    {
      "code": "NI",
      "label": "Nicaragua",
      "language": "Spanish"
    },
    {
      "code": "GN",
      "label": "Guinea",
      "language": "French"
    },
    {
      "code": "AI",
      "label": "Anguilla",
      "language": "English"
    },
    {
      "code": "AX",
      "label": "Åland Islands",
      "language": "Swedish"
    },
    {
      "code": "BE",
      "label": "Belgium",
      "language": "German, French, Dutch"
    },
    {
      "code": "PT",
      "label": "Portugal",
      "language": "Portuguese"
    },
    {
      "code": "DK",
      "label": "Denmark",
      "language": "Danish"
    },
    {
      "code": "PH",
      "label": "Philippines",
      "language": "English, Filipino"
    },
    {
      "code": "WF",
      "label": "Wallis and Futuna",
      "language": "French"
    },
    {
      "code": "AT",
      "label": "Austria",
      "language": "German"
    },
    {
      "code": "GW",
      "label": "Guinea-Bissau",
      "language": "Portuguese, Upper Guinea Creole"
    },
    {
      "code": "MC",
      "label": "Monaco",
      "language": "French"
    },
    {
      "code": "NA",
      "label": "Namibia",
      "language": "Afrikaans, German, English, Herero, Khoekhoe, Kwangali, Lozi, Ndonga, Tswana"
    },
    {
      "code": "UM",
      "label": "United States Minor Outlying Islands",
      "language": "English"
    },
    {
      "code": "CR",
      "label": "Costa Rica",
      "language": "Spanish"
    },
    {
      "code": "BA",
      "label": "Bosnia and Herzegovina",
      "language": "Bosnian, Croatian, Serbian"
    },
    {
      "code": "MO",
      "label": "Macau",
      "language": "Portuguese, Chinese"
    },
    {
      "code": "MZ",
      "label": "Mozambique",
      "language": "Portuguese"
    },
    {
      "code": "RE",
      "label": "Réunion",
      "language": "French"
    },
    {
      "code": "ME",
      "label": "Montenegro",
      "language": "Montenegrin"
    },
    {
      "code": "KP",
      "label": "North Korea",
      "language": "Korean"
    },
    {
      "code": "MP",
      "label": "Northern Mariana Islands",
      "language": "Carolinian, Chamorro, English"
    },
    {
      "code": "UA",
      "label": "Ukraine",
      "language": "Ukrainian"
    },
    {
      "code": "IQ",
      "label": "Iraq",
      "language": "Arabic, Aramaic, Sorani"
    },
    {
      "code": "GS",
      "label": "South Georgia",
      "language": "English"
    },
    {
      "code": "AO",
      "label": "Angola",
      "language": "Portuguese"
    },
    {
      "code": "SL",
      "label": "Sierra Leone",
      "language": "English"
    },
    {
      "code": "FM",
      "label": "Micronesia",
      "language": "English"
    },
    {
      "code": "CU",
      "label": "Cuba",
      "language": "Spanish"
    },
    {
      "code": "TC",
      "label": "Turks and Caicos Islands",
      "language": "English"
    },
    {
      "code": "RS",
      "label": "Serbia",
      "language": "Serbian"
    },
    {
      "code": "EC",
      "label": "Ecuador",
      "language": "Spanish"
    },
    {
      "code": "FO",
      "label": "Faroe Islands",
      "language": "Danish, Faroese"
    },
    {
      "code": "AQ",
      "label": "Antarctica",
      "language": ""
    },
    {
      "code": "PS",
      "label": "Palestine",
      "language": "Arabic"
    },
    {
      "code": "TR",
      "label": "Turkey",
      "language": "Turkish"
    },
    {
      "code": "KI",
      "label": "Kiribati",
      "language": "English, Gilbertese"
    },
    {
      "code": "KZ",
      "label": "Kazakhstan",
      "language": "Kazakh, Russian"
    },
    {
      "code": "GI",
      "label": "Gibraltar",
      "language": "English"
    },
    {
      "code": "IS",
      "label": "Iceland",
      "language": "Icelandic"
    },
    {
      "code": "PW",
      "label": "Palau",
      "language": "English, Palauan"
    },
    {
      "code": "QA",
      "label": "Qatar",
      "language": "Arabic"
    },
    {
      "code": "CH",
      "label": "Switzerland",
      "language": "French, Swiss German, Italian, Romansh"
    },
    {
      "code": "PF",
      "label": "French Polynesia",
      "language": "French"
    },
    {
      "code": "PN",
      "label": "Pitcairn Islands",
      "language": "English"
    },
    {
      "code": "JO",
      "label": "Jordan",
      "language": "Arabic"
    },
    {
      "code": "MM",
      "label": "Myanmar",
      "language": "Burmese"
    },
    {
      "code": "TH",
      "label": "Thailand",
      "language": "Thai"
    },
    {
      "code": "BQ",
      "label": "Caribbean Netherlands",
      "language": "English, Dutch, Papiamento"
    },
    {
      "code": "AW",
      "label": "Aruba",
      "language": "Dutch, Papiamento"
    },
    {
      "code": "GP",
      "label": "Guadeloupe",
      "language": "French"
    },
    {
      "code": "NG",
      "label": "Nigeria",
      "language": "English"
    },
    {
      "code": "BH",
      "label": "Bahrain",
      "language": "Arabic"
    },
    {
      "code": "LA",
      "label": "Laos",
      "language": "Lao"
    },
    {
      "code": "CC",
      "label": "Cocos (Keeling) Islands",
      "language": "English"
    },
    {
      "code": "DJ",
      "label": "Djibouti",
      "language": "Arabic, French"
    },
    {
      "code": "SB",
      "label": "Solomon Islands",
      "language": "English"
    },
    {
      "code": "HM",
      "label": "Heard Island and McDonald Islands",
      "language": "English"
    },
    {
      "code": "IN",
      "label": "India",
      "language": "English, Hindi, Tamil"
    },
    {
      "code": "SM",
      "label": "San Marino",
      "language": "Italian"
    },
    {
      "code": "LU",
      "label": "Luxembourg",
      "language": "German, French, Luxembourgish"
    },
    {
      "code": "SX",
      "label": "Sint Maarten",
      "language": "English, French, Dutch"
    },
    {
      "code": "FK",
      "label": "Falkland Islands",
      "language": "English"
    },
    {
      "code": "CF",
      "label": "Central African Republic",
      "language": "French, Sango"
    },
    {
      "code": "BW",
      "label": "Botswana",
      "language": "English, Tswana"
    },
    {
      "code": "CW",
      "label": "Curaçao",
      "language": "English, Dutch, Papiamento"
    },
    {
      "code": "GG",
      "label": "Guernsey",
      "language": "English, French, Guernésiais"
    },
    {
      "code": "NO",
      "label": "Norway",
      "language": "Norwegian Nynorsk, Norwegian Bokmål, Sami"
    },
    {
      "code": "GA",
      "label": "Gabon",
      "language": "French"
    },
    {
      "code": "ZW",
      "label": "Zimbabwe",
      "language": "Chibarwe, English, Kalanga, Khoisan, Ndau, Northern Ndebele, Chewa, Shona, Sotho, Tonga, Tswana, Tsonga, Venda, Xhosa, Zimbabwean Sign Language"
    },
    {
      "code": "LS",
      "label": "Lesotho",
      "language": "English, Sotho"
    },
    {
      "code": "SI",
      "label": "Slovenia",
      "language": "Slovene"
    },
    {
      "code": "AR",
      "label": "Argentina",
      "language": "Guaraní, Spanish"
    },
    {
      "code": "BF",
      "label": "Burkina Faso",
      "language": "French"
    },
    {
      "code": "YE",
      "label": "Yemen",
      "language": "Arabic"
    },
    {
      "code": "CD",
      "label": "DR Congo",
      "language": "French, Kikongo, Lingala, Tshiluba, Swahili"
    },
    {
      "code": "OM",
      "label": "Oman",
      "language": "Arabic"
    },
    {
      "code": "ID",
      "label": "Indonesia",
      "language": "Indonesian"
    },
    {
      "code": "NR",
      "label": "Nauru",
      "language": "English, Nauru"
    },
    {
      "code": "RW",
      "label": "Rwanda",
      "language": "English, French, Kinyarwanda"
    },
    {
      "code": "MK",
      "label": "North Macedonia",
      "language": "Macedonian"
    },
    {
      "code": "XK",
      "label": "Kosovo",
      "language": "Albanian, Serbian"
    },
    {
      "code": "NL",
      "label": "Netherlands",
      "language": "Dutch"
    },
    {
      "code": "TN",
      "label": "Tunisia",
      "language": "Arabic"
    },
    {
      "code": "SS",
      "label": "South Sudan",
      "language": "English"
    },
    {
      "code": "HN",
      "label": "Honduras",
      "language": "Spanish"
    },
    {
      "code": "VC",
      "label": "Saint Vincent and the Grenadines",
      "language": "English"
    },
    {
      "code": "LK",
      "label": "Sri Lanka",
      "language": "Sinhala, Tamil"
    },
    {
      "code": "HK",
      "label": "Hong Kong",
      "language": "English, Chinese"
    }
  ]
