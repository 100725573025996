import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';
import LogoutButton from '../logoutButton/LogoutButton';
import Cookies from 'js-cookie';
import './NavigationMenu.scss'

export default function NavigationMenu({ onLogout }) {

    const user = Cookies.get('username');
    const role = Cookies.get('role');

    const [anchorEl1, setAnchorEl1] = React.useState(null);
    const [anchorEl2, setAnchorEl2] = React.useState(null);
    const [anchorEl3, setAnchorEl3] = React.useState(null);


    const open1 = Boolean(anchorEl1);
    const open2 = Boolean(anchorEl2);
    const open3 = Boolean(anchorEl3);

    const handleClick1 = (event) => {
        setAnchorEl1(event.currentTarget);
    };
    const handleClick2 = (event) => {
        setAnchorEl2(event.currentTarget);
    };
    const handleClick3 = (event) => {
        setAnchorEl3(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl1(null);
    };
    const handleClose2 = () => {
        setAnchorEl2(null);
    };
    const handleClose3 = () => {
        setAnchorEl3(null);
    };

    return (
        <nav className='main-nav'>
            <img src={`/image//logo.png`} alt="Logo" className="logo" />
            <Button component={Link} to="/user">
                User
            </Button>
            <Button component={Link} to="/scans">
                Scans
            </Button>
            <Button
                id="basic-button-1"
                aria-controls={open1 ? 'basic-menu-1' : undefined}
                aria-haspopup="true"
                aria-expanded={open1 ? 'true' : undefined}
                onClick={handleClick1}
            >
                Coupon
            </Button>
            <Menu
                id="basic-menu-1"
                anchorEl={anchorEl1}
                open={open1}
                onClose={handleClose}
                MenuListProps={{
                'aria-labelledby': 'basic-button-1',
                }}
            >
                <MenuItem component={Link} to="/coupon/list" onClick={handleClose}>List</MenuItem>
                <MenuItem component={Link} to="/coupon/add" onClick={handleClose}>Add</MenuItem>
                <MenuItem component={Link} to="/coupon/edit" onClick={handleClose}>Edit</MenuItem>
                <MenuItem component={Link} to="/coupon-activate" onClick={handleClose}>Activate</MenuItem>
            </Menu>
            <Button component={Link} to="/credits">
                Credits
            </Button>
            <Button
                id="basic-button-3"
                aria-controls={open3 ? 'basic-menu-3' : undefined}
                aria-haspopup="true"
                aria-expanded={open3 ? 'true' : undefined}
                onClick={handleClick3}
            >
                Reports
            </Button>
            <Menu
                id="basic-menu-3"
                anchorEl={anchorEl3}
                open={open3}
                onClose={handleClose3}
                MenuListProps={{
                'aria-labelledby': 'basic-button-3',
                }}
            >
                <MenuItem component={Link} to="/reports/orders" onClick={handleClose3}>Orders</MenuItem>
                <MenuItem component={Link} to="/reports/log-files" onClick={handleClose3}>Log Files</MenuItem>
            </Menu>
            {role === 'admin' && (
                <>
                    <Button
                        id="admin-button"
                        aria-controls={open2 ? 'admin-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open2 ? 'true' : undefined}
                        onClick={handleClick2}
                    >
                        Administration
                    </Button>
                    <Menu
                        id="admin-menu"
                        anchorEl={anchorEl2}
                        open={open2}
                        onClose={handleClose2}
                        MenuListProps={{
                            'aria-labelledby': 'admin-button',
                        }}
                    >
                        <MenuItem component={Link} to="/admin/users-list" onClick={handleClose2}>
                            Users list
                        </MenuItem>
                        <MenuItem component={Link} to="/admin/users-add" onClick={handleClose2}>
                            Add Users
                        </MenuItem>
                    </Menu>
                </>
            )}
            <div className="logout-button-wrapper">
                <span className="username"><strong>{user}</strong></span>
                <LogoutButton onLogout={onLogout} />
            </div>
        </nav>
    );
}
