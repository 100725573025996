import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { FormControl, InputLabel, Select, MenuItem, Button, Stack, TextField, CircularProgress, Snackbar } from '@mui/material';
import { green } from '@mui/material/colors';
import Cookies from 'js-cookie';
import './CouponEdit.scss';

export default function CouponEdit() {
    const { id } = useParams();
    const location = useLocation();
    const isAddMode = location.pathname.includes("/coupon/add");
    const [coupons, setCoupons] = useState([]);
    const [couponData, setCouponData] = useState({
        id: "",
        couponId: "",
        startDate: "",
        endDate: "",
        idPay: "",
        active: "",
        stato: "",
        daysValid: 0
    });
    const [selectedCouponCode, setSelectedCouponCode] = useState('');
    const [selectedCouponId, setSelectedCouponId] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const timer = useRef();
    const apiKey = Cookies.get('key');

    useEffect(() => {
        const fetchCouponData = async (couponId) => {
            try {
                const response = await fetch(`https://admin.omnivisix.io/api/getCoupon.php?coupon_id=${couponId}`, {
                    headers: {
                        'api_key': apiKey
                    }
                });
                const data = await response.json();
                if (data && data.data) {
                    const selectedCoupon = data.data;
                    setCouponData({
                        id: selectedCoupon.coupon_id || "",
                        couponId: selectedCoupon.code || "",
                        startDate: selectedCoupon.start_date || "",
                        endDate: selectedCoupon.end_date || "",
                        idPay: selectedCoupon.idpay || "",
                        active: selectedCoupon.attivato || "",
                        stato: selectedCoupon.stato || "",
                        daysValid: selectedCoupon.days_valid || 0
                    });
                }
            } catch (error) {
                console.error('Error fetching coupon data:', error);
            }
        };

        if (!isAddMode) {
            if (id) {
                fetchCouponData(id);
            } else {
                const fetchCoupons = async () => {
                    try {
                        const response = await fetch('https://admin.omnivisix.io/api/listCoupons.php', {
                            headers: {
                                'api_key': apiKey
                            }
                        });
                        const data = await response.json();
                        if (data && data.data) {
                            setCoupons(data.data);
                        }
                    } catch (error) {
                        console.error('Error while fetching coupons:', error);
                    }
                };
                fetchCoupons();
            }
        }
    }, [isAddMode, id, apiKey]);

    useEffect(() => {
        const currentTimer = timer.current;
        return () => {
            clearTimeout(currentTimer);
        };
    }, []);

    const handleCouponCodeChange = async (event) => {
        const selectedCouponId = event.target.value;
        setSelectedCouponCode(selectedCouponId);
        setSelectedCouponId('');
        fetchCouponData(selectedCouponId);
    };

    const handleCouponIdChange = async (event) => {
        const selectedCouponId = event.target.value;
        setSelectedCouponId(selectedCouponId);
        setSelectedCouponCode('');
        fetchCouponData(selectedCouponId);
    };

    const fetchCouponData = async (couponId) => {
        try {
            const response = await fetch(`https://admin.omnivisix.io/api/getCoupon.php?coupon_id=${couponId}`, {
                headers: {
                    'api_key': apiKey
                }
            });
            const data = await response.json();
            if (data && data.data) {
                const selectedCoupon = data.data;
                setCouponData({
                    id: selectedCoupon.coupon_id || "",
                    couponId: selectedCoupon.code || "",
                    startDate: selectedCoupon.start_date || "",
                    endDate: selectedCoupon.end_date || "",
                    active: selectedCoupon.attivato || "",
                    idPay: selectedCoupon.idpay || "",
                    stato: selectedCoupon.stato || "",
                    daysValid: selectedCoupon.days_valid || 0
                });
            }
        } catch (error) {
            console.error('Error fetching coupon data:', error);
        }
    };

    const calculateDaysValid = (start, end) => {
        const startDate = new Date(start);
        const endDate = new Date(end);
        const difference = endDate - startDate;
        return Math.ceil(difference / (1000 * 60 * 60 * 24));
    };

    const apiUrl = isAddMode 
        ? 'https://admin.omnivisix.io/api/addCoupon.php'
        : 'https://admin.omnivisix.io/api/editCoupon.php';
      
    const handleCouponEdit = async () => {
        setLoading(true);
        setSuccess(false);

        const formData = new FormData();

        formData.append("id", couponData.id);
        formData.append("code", couponData.couponId);
        formData.append("start_date", couponData.startDate);
        formData.append("end_date", couponData.endDate);
        formData.append("days_valid", couponData.daysValid);
        formData.append("attivato", couponData.active);
        formData.append("idpay", couponData.idPay);
        formData.append("stato", couponData.stato);
    
        try {
            const response = await fetch(apiUrl, {
                headers: {
                    'api_key': apiKey
                },
                method: 'POST',
                body: formData,
            });
    
            setLoading(false);
    
            if (response.ok) {
                console.log("Data saved successfully");
                setSuccess(true);
                setOpenSnackbar(true);
            } else {
                console.error("Failed to save data");
            }
        } catch (error) {
            console.error("Error saving data:", error);
            setLoading(false);
        }
    };

    const handleCloseSnackbar = () => setOpenSnackbar(false);

    const buttonSx = {
        ...(success && {
            bgcolor: green[500],
            '&:hover': {
                bgcolor: green[700],
            },
        }),
    };

    const handleStartDateChange = (e) => {
        const newStartDate = e.target.value;
        const newDaysValid = calculateDaysValid(newStartDate, couponData.endDate);
        if (newStartDate && couponData.endDate && newStartDate > couponData.endDate) {
            console.error("Start date cannot be later than end date.");
            setOpenSnackbar(true);
        } else {
            setCouponData({ ...couponData, startDate: newStartDate, daysValid: newDaysValid });
        }
    };

    const handleEndDateChange = (e) => {
        const newEndDate = e.target.value;
        const newDaysValid = calculateDaysValid(couponData.startDate, newEndDate);
        if (couponData.startDate && newEndDate && couponData.startDate > newEndDate) {
            console.error("End date cannot be earlier than start date.");
            setOpenSnackbar(true);
        } else {
            setCouponData({ ...couponData, endDate: newEndDate, daysValid: newDaysValid });
        }
    };

    return (
        <div>
            <h2 className='coupon-select__header'>Select coupon</h2>
            {!isAddMode && !id && (
                <Stack direction="row" spacing={4} sx={{ marginBottom: 2 }}>
                    <FormControl sx={{ width: 234, marginRight: 1 }}>
                        <InputLabel id="coupon-select-code-label">By coupon code</InputLabel>
                        <Select
                            labelId="coupon-select-code-label"
                            id="coupon-select-code"
                            label="By coupon code"
                            value={selectedCouponCode}
                            onChange={handleCouponCodeChange}
                        >
                            {coupons.map((coupon) => (
                                <MenuItem key={coupon.coupon_id} value={coupon.coupon_id}>
                                    {coupon.code}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <h3 className='coupon-select__separate-header'>OR</h3>
                    <FormControl sx={{ width: 234 }}>
                        <InputLabel id="coupon-select-id-label">By coupon ID</InputLabel>
                        <Select
                            labelId="coupon-select-id-label"
                            id="coupon-select-id"
                            label="By coupon ID"
                            value={selectedCouponId}
                            onChange={handleCouponIdChange}
                        >
                            {coupons.map((coupon) => (
                                <MenuItem key={coupon.coupon_id} value={coupon.coupon_id}>
                                    {coupon.coupon_id}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Stack>
            )}
            <div className="coupon__info-wrapper">
                <Stack spacing={3} sx={{ width: 800 }}>
                    <h2 className='form__title'>Coupon INFO</h2>
                    {!isAddMode && (
                        <div className='coupon__inputs-wrapper'>
                            <TextField 
                                sx={{ width: 120 }}
                                className="form__input" 
                                id="id" 
                                label="ID" 
                                variant="outlined" 
                                disabled 
                                value={couponData.id} 
                            />
                        </div>
                    )}
                    <div className='coupon__inputs-wrapper'>
                        <TextField 
                            sx={{ width: 450 }}
                            className="form__input" 
                            id="coupon_id" 
                            label="Coupon ID" 
                            variant="outlined" 
                            disabled={!isAddMode}
                            value={couponData.couponId} 
                            onChange={(e) => setCouponData({ ...couponData, couponId: e.target.value })}
                        />
                    </div>
                    <div className='coupon__inputs-wrapper'>
                        <TextField 
                            sx={{ width: 200 }}
                            className="form__input form__input--small" 
                            id="start_date" 
                            label="Valid from" 
                            type="date"
                            variant="outlined" 
                            value={couponData.startDate}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={handleStartDateChange}
                        />

                        <TextField 
                            sx={{ width: 200 }}
                            className="form__input form__input--small" 
                            id="end_date" 
                            label="to" 
                            type="date"
                            variant="outlined" 
                            value={couponData.endDate}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={handleEndDateChange}
                        />
                    </div>
                    <div className='coupon__inputs-wrapper'>
                        <TextField 
                            sx={{ width: 450 }}
                            className="form__input" 
                            id="id_pay" 
                            label="ID Pay" 
                            variant="outlined" 
                            disabled={!isAddMode}
                            value={couponData.idPay} 
                            onChange={(e) => setCouponData({ ...couponData, idPay: e.target.value })}
                        />
                    </div>
                    <div className='coupon__inputs-wrapper'>
                        <FormControl sx={{ width: 180 }}>
                            <InputLabel id="active-label">Active</InputLabel>
                            <Select
                                labelId="active-label"
                                id="active"
                                value={couponData.active === "1" ? "Active" : "Inactive"}
                                label="Active"
                                onChange={(e) => setCouponData({ 
                                    ...couponData, 
                                    active: e.target.value === "Active" ? "1" : "0"
                                })}
                            >
                                <MenuItem value="Active">Active</MenuItem>
                                <MenuItem value="Inactive">Inactive</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl sx={{ width: 180 }}>
                            <InputLabel id="stato-label">Stato</InputLabel>
                            <Select
                                labelId="stato-label"
                                id="stato"
                                value={couponData.stato === "1" ? "Used" : "Not Used"}
                                label="Stato"
                                onChange={(e) => setCouponData({ 
                                    ...couponData, 
                                    stato: e.target.value === "Used" ? "1" : "0"
                                })}
                            >
                                <MenuItem value="Used">Used</MenuItem>
                                <MenuItem value="Not Used">Not Used</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <Stack direction="row" spacing={2}>
                        <Button 
                            className="coupon__btn" 
                            variant="contained" 
                            sx={buttonSx}
                            disabled={loading}
                            onClick={() => handleCouponEdit()}
                        >
                            {loading ? <CircularProgress size={24} color="inherit" /> : 'Save'}
                        </Button>
                        <Snackbar
                            open={openSnackbar}
                            autoHideDuration={6000}
                            onClose={handleCloseSnackbar}
                            message="Coupon saved successfully"
                        />
                    </Stack>
                </Stack>
            </div>
        </div>
    );
}
