import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Stack, TextField, Button, Snackbar, Alert } from '@mui/material';
import Cookies from 'js-cookie';
import '../CouponActivate/CouponActivate.scss';

export default function CouponActivate() {
    const { id } = useParams();
    const [couponId, setCouponId] = useState(id || '');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [couponData, setCouponData] = useState(null);
    const [refreshKey, setRefreshKey] = useState(0); // key for component rerender (after coupon form submit)
    const timer = useRef();
    const apiKey = Cookies.get('key');

    useEffect(() => {
        if (id) {
            setCouponId(id);
        } else {
            setCouponId('');
            setCouponData(null);
        }
    }, [id, refreshKey]);

    const handleActivateClick = async () => {
        if (couponId.trim() === '') {
            setErrorText("Field can't be empty");
            return;
        }

        if (!loading) {
            setSuccess(false);
            setLoading(true);
            setErrorText('');
            try {
                const response = await fetch(`https://admin.omnivisix.io/api/getCoupon.php?coupon_id=${couponId}`, {
                    headers: {
                        'api_key': apiKey
                    }
                });
                const result = await response.json();
                if (result.data) {
                    setCouponData(result.data);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            } catch (error) {
                console.error('Error:', error);
                setLoading(false);
            }
        }
    };

    const handleChange = (event) => {
        setCouponId(event.target.value);
        if (errorText !== '') setErrorText('');
    };

    const calculateDaysValid = (start, end) => {
        const startDate = new Date(start);
        const endDate = new Date(end);
        const difference = endDate - startDate;
        return Math.ceil(difference / (1000 * 60 * 60 * 24));
    };

    const calculateEndDate = (start, days) => {
        const startDate = new Date(start);
        startDate.setDate(startDate.getDate() + parseInt(days));
        return startDate.toISOString().split('T')[0]; // format as YYYY-MM-DD
    };

    const handleFormChange = (event) => {
        const { name, value } = event.target;
        let updatedCouponData = { ...couponData, [name]: value };

        if (name === 'start_date' || name === 'end_date') {
            updatedCouponData.days_valid = calculateDaysValid(updatedCouponData.start_date, updatedCouponData.end_date);
        } else if (name === 'days_valid') {
            updatedCouponData.end_date = calculateEndDate(updatedCouponData.start_date, value);
        }

        setCouponData(updatedCouponData);
    };

    const handleFormSubmit = async () => {
        if (!loading) {
            setLoading(true);
            try {
                const formData = new FormData();
                formData.append('id', couponData.coupon_id);
                formData.append('code', couponData.code);
                formData.append('start_date', couponData.start_date);
                formData.append('end_date', couponData.end_date);
                formData.append('attivato', couponData.attivato);
                formData.append('idpay', couponData.idpay);
                formData.append("stato", couponData.stato);
                formData.append("days_valid", couponData.days_valid);

                const response = await fetch('https://admin.omnivisix.io/api/editCoupon.php', {
                    method: 'POST',
                    headers: {
                        'api_key': apiKey
                    },
                    body: formData
                });

                const result = await response.json();
                if (result.success) {
                    setOpenSnackbar(true);
                    setSuccess(true);
                    timer.current = setTimeout(() => {
                        setLoading(false);
                        setOpenSnackbar(false);
                        setCouponId('');
                        setCouponData(null);
                        setRefreshKey(prevKey => prevKey + 1);
                    }, 2000);
                } else {
                    setLoading(false);
                }
            } catch (error) {
                console.error('Error:', error);
                setLoading(false);
            }
        }
    };

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    return (
        <div key={refreshKey}>
            <Stack spacing={2}>
                <h2>Coupon Activate</h2>
                <div className='coupon-form__wrapper'>
                    <TextField
                        id="couponId"
                        label="Coupon ID"
                        variant="outlined"
                        value={couponId}
                        onChange={handleChange}
                        error={!!errorText}
                        helperText={errorText}
                        sx={{ marginRight: '40px' }}
                    />
                    <Button variant="contained" onClick={handleActivateClick} disabled={loading}>
                        Activate
                    </Button>
                </div>
            </Stack>
            {couponData && (
                <div className='coupon-activate-form__wrapper'>
                    <TextField
                        name="coupon_id"
                        label="Selected Coupon ID"
                        variant="outlined"
                        value={couponData.coupon_id}
                        onChange={handleFormChange}
                        disabled
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        name="code"
                        label="Code"
                        variant="outlined"
                        value={couponData.code}
                        onChange={handleFormChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        name="start_date"
                        label="Start Date"
                        variant="outlined"
                        value={couponData.start_date}
                        onChange={handleFormChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        name="end_date"
                        label="End Date"
                        variant="outlined"
                        value={couponData.end_date}
                        onChange={handleFormChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        name="days_valid"
                        label="Days Valid"
                        variant="outlined"
                        value={couponData.days_valid}
                        onChange={handleFormChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        name="attivato"
                        label="Activated"
                        variant="outlined"
                        value={couponData.attivato}
                        onChange={handleFormChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        name="stato"
                        label="State"
                        variant="outlined"
                        value={couponData.stato}
                        onChange={handleFormChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        name="idpay"
                        label="Payment ID"
                        variant="outlined"
                        value={couponData.idpay}
                        onChange={handleFormChange}
                        fullWidth
                        margin="normal"
                    />
                    <Button 
                        variant="contained" 
                        onClick={handleFormSubmit} 
                        disabled={loading}
                        className="coupon-form__submit-button"
                    >
                        Submit
                    </Button>
                </div>
            )}
            {openSnackbar && (
                <Snackbar open={openSnackbar} autoHideDuration={2000} onClose={handleSnackbarClose}>
                    <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
                        Coupon updated successfully!
                    </Alert>
                </Snackbar>
            )}
        </div>
    );
}
