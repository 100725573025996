import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate, useParams } from 'react-router-dom';

import ReportsDetailsDialog from "../ReportsDetailsDialog/ReportsDetailsDialog";
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Tooltip from '@mui/material/Tooltip';
import Cookies from 'js-cookie';

export default function ReportsLogFiles() {
    const { id } = useParams();
    const [rows, setRows] = React.useState([]);
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const [selectedLog, setSelectedLog] = React.useState(null);
    const apiKey = Cookies.get('key');
    const navigate = useNavigate();

    React.useEffect(() => {
        const fetchData = async () => {
            try {
                const url = id 
                    ? `https://admin.omnivisix.io/api/listLogsByUser.php?id=${id}` 
                    : 'https://admin.omnivisix.io/api/listLogs.php';

                const response = await fetch(url, {
                    headers: {
                        'api_key': apiKey
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    setRows(data.data);
                } else {
                    console.error('Failed to fetch data:', response.statusText);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [apiKey, id]);

    const handleMore = (id) => {
        const log = rows.find(row => row.id === id);
        setSelectedLog(log);
        setIsDialogOpen(true);
    };

    const columns = [
        { field: 'id', headerName: 'ID', type: 'number', width: 50 },
        { field: 'user_id', headerName: 'User ID', type: 'number', width: 200 },
        { field: 'address', headerName: 'Address', width: 140 },
        { field: 'action', headerName: 'Action', width: 200 },
        { field: 'timestamp', headerName: 'Timestamp', width: 180 },
        { field: 'url', headerName: 'URL', width: 200 },
        {
          field: 'actions',
          headerName: 'Actions',
          width: 100,
          renderCell: (params) => (
            <Tooltip title="View Details">
                <IconButton size="small" onClick={() => handleMore(params.row.id)}>
                    <VisibilityIcon fontSize="small" />
                </IconButton>
            </Tooltip>
          )
        }
    ];

    return (
        <div style={{ height: 631, width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 10 },
                    },
                }}
                pageSizeOptions={[10, 20, 50]}
            />
            <ReportsDetailsDialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} log={selectedLog} />
        </div>
    );
};
